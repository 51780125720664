<template>
	<article class="padding content content-wrapper">
		<v-container>
			<v-row>
				<v-col v-if="!offline" :cols="12 - curveCols">
					<v-card outlined class="fill-height base-padding">
						<v-card-title><h3>Report settings</h3></v-card-title>
						<v-card-text>	
							<div class="grid">
								<v-select outlined class="chevron mb-4 mt-2" :items="reportTypes" v-model="reportType" item-text="title" return-object
									label="Type of report" persistent-hint hide-details>
									<template slot="append">
	  									<v-icon>mdi-chevron-down</v-icon>
									</template>
								</v-select>
								<v-select outlined class="chevron mb-4" :items="sous" v-model="reportSystemOfUnit" :label="$t('Site.SystemOfUnits')"
									persistent-hint hide-details>
									<template slot="append">
										<v-icon>mdi-chevron-down</v-icon>
									</template>
								</v-select>
								<v-select outlined class="chevron mb-4" v-if="languages && languages.length > 1" :items="languages" v-model="language"
									:label="$t('app.language')" persistent-hint hide-details>
								<template slot="append">
										<v-icon>mdi-chevron-down</v-icon>
									</template>
								</v-select>					
								<v-select outlined class="chevron mb-2" v-if="locales && locales.length > 1" :items="locales" v-model="locale"
									:label="$t('app.locale')" persistent-hint hide-details>
									<template slot="append">
										<v-icon>mdi-chevron-down</v-icon>
									</template>
								</v-select>
							</div>	
							<div class="grid mt-n3">
								<v-switch v-if="showCurveOnlyOption" dense v-model="curveOnly" :disabled="!hasSettings" label="Performance curve"
									hide-details style="font-style: italic;" />
								<template v-if="!curveOnly">
									<v-switch dense v-model="showWarnings" :disabled="!hasSettings" label="Include warnings" hide-details />
									<v-switch dense v-model="showComments" :disabled="!hasSettings" label="Include notes" hide-details />
									<v-switch dense v-model="showEstimations" :disabled="!hasSettings" label="Include system estimated values" hide-details />
								</template>
								<v-switch v-if="hasPipes" dense v-model="showPipes" :disabled="!hasSettings" label="Include piping" hide-details />
								<v-switch dense v-model="showCurves" :disabled="!hasSettings" hide-details>
									<template v-slot:label>
										Include curves
										<v-tooltip v-if="manyPages" top>
											<template v-slot:activator="{ on }">
												<v-icon v-on="on" color="warning" class="ml-1">error</v-icon>
											</template>
											It might not be possible to create a PDF with curves for this large project. Consider disabling them.
										</v-tooltip>
									</template>
								</v-switch>
								<v-switch dense v-if="singleSizingReport && sizing && (sizing.MDP || sizing.Staged)" :disabled="!hasSettings"
									v-model="showCurrentOnly" label="Only current stage/duty point" hide-details />
							</div>

							<div>
								<InfoBox v-if="souDiff" class="mt-4 mb-0 max-width">
									System of units differs from project settings
								</InfoBox>
							</div>

							<div class="grid buttons mt-5">
								<v-btn dark @click="generateSummary(false)" :disabled="working" v-text="reportType && reportType.downloadText" />
								<v-btn @click="generateSummary(true)" :disabled="working || !reportType || !reportType.previewable">Show preview</v-btn>
								<v-progress-circular v-if="working" :size="20" indeterminate class="mt-2" />
								</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col v-if="!offline && sizing && hasSettings"  :cols="curveCols">
					<v-card outlined class="fill-height base-padding">
						<v-card-title><h3>Report curve settings</h3></v-card-title>
						<v-card-text>
							<PumpCurve :sizingId="sizing.id" :variants="variants" :noZoom="true" @settings="curveSettingsChanged"
								settingsKey='reportCurve' :forceImperial="reportSystemOfUnit === 'Imperial'" />
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" v-if="sizing">
					<v-card outlined class="base-padding">
						<v-card-title><h3>Warnings and errors</h3></v-card-title>
						<v-card-text>
							<WarningList :sizing="sizing" :variants="variants" />
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		<v-dialog v-model="showPreview" width="1150" height="90%" @keydown.esc="showPreview = false">
			<div class="preview-continer">
				<div class="inner-container">
					<v-btn small @click="showPreview = false" class="close-preview"><b>X</b></v-btn>
				</div>
				<iframe v-show="showPreview" width="1120" :height="frameHeight" id="previewHtml" frameborder="0"></iframe>
			</div>
		</v-dialog>
	</article>
</template>

<style lang="scss" scoped>
.grid {
	display: grid;
	row-gap: 0;
	grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
	grid-gap: 0 16px;
	&.buttons {
		grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
		grid-gap: 10px 16px;
	}
}

.preview-continer {
	background-color: white;
	padding-top: 60px;

	.inner-container {
		position: relative;
	}
	.close-preview {
		position: absolute; right: 20px; top: -40px;
	}
}

@media only screen and (max-width: 960px) {
	.content-wrapper {
		margin-top:55px;
	}
}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import ReportingService from '@/services/reporting.service';
	import { ReportInputs } from 'types/dto/Reports';
	import store, { AuthGetters, SnackActions, SizingGetters, NetworkGetters } from '@/store';
	import PumpCurve from '@/components/PumpCurve.vue';
	import WarningList from '@/components/WarningList.vue';
	import InfoBox from '@/components/InfoBox.vue';
	import { DutyPoints } from '@/common/DutyPoints';
	import { PumpProject, PumpDocument, TDHMode } from 'types/dto/CalcServiceDomain';
	import InsightService from '@/services/insight.service';
	import i18n, { locales, suggestLocale, fallbackLanguage, languages, fallbackLocale } from '@/i18n';
	import { ParamBag } from '@/common/ParamBag';
	import { AuthActions, UserSetting } from '@/store/modules/auth.store';
import { PerformancePreview } from '@/common/PerformancePreview';

	const enum ReportId {
		SizingSummary, ProjectSummary, ProjectExcel
	}

	interface ReportType {
		id: ReportId;
		title: any;
		downloadText: string;
		previewable?: boolean;
		disabled?: boolean;
	}

	let lastSettings: any = null;

	@Component({
		name: 'project',
		components: {
			PumpCurve,
			WarningList,
			InfoBox
		},
	})
	export default class ReportCreation extends Vue {
		@Prop() public project: PumpProject;
		@Prop() public sizing: PumpDocument;

		public showWarnings: boolean = lastSettings?.ShowWarnings ?? true;
		public showComments: boolean = lastSettings?.ShowComments ?? false;
		public showEstimations: boolean = lastSettings?.ShowEstimations ?? false;
		public showPipes: boolean = false;
		public showCurves: boolean = true;
		public curveOnly: boolean = true;
		public selectedSystemOfUnit: string = lastSettings?.UseImperial ? 'Imperial' : null;
		public language: string = lastSettings?.Language || i18n.locale || fallbackLanguage;
		public locale = lastSettings?.locale || fallbackLocale;

		public showCurrentOnly: boolean = false;
		public curveSettings: Partial<ReportInputs> = null;
		public showPreview: boolean = false;
		public reportType: ReportType = null;
		public working = false;
		public curveCols = 8;

		public get reportSystemOfUnit() {
			return this.selectedSystemOfUnit || ParamBag.getSizingSOU(this.sizing?.id) ||
				store.get(AuthGetters.systemOfUnit) || 'Metric';
		}

		public set reportSystemOfUnit(sou) {
			if (sou)
				this.selectedSystemOfUnit = sou;
		}

		public get souDiff() {
			const projSOU = this.project?.Site?.SystemOfUnits;
			return Boolean(projSOU && projSOU !== this.reportSystemOfUnit);
		}

		public get offline() {
			return !store.get(NetworkGetters.connected);
		}

		public get variants() {
			if (!this.sizing)
				return [];
			if (this.showCurrentOnly && this.sizing)
				return [this.sizing];
			return DutyPoints.sizingWithChildren(this.sizing);
		}

		public get reportTypes() {
			return [
				{ id: ReportId.SizingSummary, title: 'Sizing summary', downloadText: 'Create PDF', previewable: true, disabled: !this.hasSelectedPump },
				{ id: ReportId.ProjectSummary, title: 'Full project summary', downloadText: 'Create PDF', previewable: true, disabled: !this.project },
				{ id: ReportId.ProjectExcel, title: 'Project Excel export', downloadText: 'Create file', disabled: !this.project }
			] as ReportType[];
		}

		public get sous() {
			return [{ text: this.$t('SystemOfUnits.Metric'), value: 'Metric'}, { text: this.$t('SystemOfUnits.Imperial'), value: 'Imperial' }];
		}

		public get languages() {
			return languages();
		}

		public get locales() {
			return locales();
		}

		public get hasSettings() {
			return !!this.reportType?.previewable;
		}

		public get hasPipes() {
			return this.includedSizings?.some(x => x.Data?.Heads?.TDHMode === TDHMode.Calculate &&
				(x.Data.Inlet?.Pipes?.length > 0 || x.Data.Outlet?.Pipes?.length > 0)) || false;
		}

		public get hasSelectedPump(): boolean {
			return this.sizing?.Data?.Pump?.Id?.length > 0;
		}

		public get frameHeight() {
			return this.showPipes ? 3000 + 120 * (this.variants?.length) : 2850;
		}

		public get singleSizingReport() {
			return this.reportType?.id === ReportId.SizingSummary;
		}

		public get includedSizings() {
			if (this.singleSizingReport)
				return this.variants ?? [];
			return store.get(SizingGetters.projectSizings, this.project.id) as PumpDocument[] ?? [];
		}

		public get manyPages() {
			return this.showCurves && this.includedSizings.length >= 50;
		}

		public get showCurveOnlyOption() {
			return this.reportType?.id === ReportId.SizingSummary && store.get(AuthGetters.hasRole, 'pumpadmin');
		}

		public async created() {
			this.reportType = this.reportTypes[0].disabled ? this.reportTypes[1] : this.reportTypes[0];

			const defaults = store.get(AuthGetters.setting, UserSetting.reportSettings);
			if (defaults)
				lastSettings = this.settings = defaults;

			if (!lastSettings?.locale) {
				const lang = lastSettings?.Language ?? store.get(AuthGetters.language) ?? fallbackLanguage;
				this.locale = suggestLocale(lang, store.get(AuthGetters.locale), this.reportSystemOfUnit === 'Imperial');
			}

			window?.addEventListener('resize', this.onResize, { passive: true });
		}

		public beforeDestroy() {
			window?.removeEventListener('resize', this.onResize);
		}

		public onResize() {
			this.curveCols = window.innerWidth < 1500 ? 12 : 8;
		}

		public curveSettingsChanged(settings: Partial<ReportInputs>) {
			this.curveSettings = settings;
		}

		public get settings(): Partial<ReportInputs> {
			return {
				UseImperial: this.reportSystemOfUnit === 'Imperial',
				ShowWarnings: this.showWarnings,
				ShowComments: this.showComments,
				ShowPipes: this.showPipes,
				ShowEstimations: this.showEstimations,
				ShowCurves: this.showCurves,
				CurveOnly: this.curveOnly,
				Language: this.language || undefined,
				Locale: this.locale || undefined
			};
		}

		public set settings(s: Partial<ReportInputs>) {
			if (s) {
				this.reportSystemOfUnit = s.UseImperial ? 'Imperial' : 'Metric';
				this.showWarnings = s.ShowWarnings;
				this.showComments = s.ShowComments;
				this.showPipes = s.ShowPipes;
				this.showEstimations = s.ShowEstimations;
				this.showCurves = s.ShowCurves;
				this.curveOnly = s.CurveOnly;
				this.language = s.Language || fallbackLanguage;
				this.locale = s.Locale || fallbackLocale;
			}
		}

		@Watch('settings')
		private reportSettingsChanged(value: any) {
			lastSettings = value;
			store.dispatch(AuthActions.updateSetting, { key: UserSetting.reportSettings, value });
		}

		@Watch('showCurveOnlyOption')
		private showCurveOnlyOptionChanged(value: boolean) {
			if (!value)
				this.curveOnly = false;
		}

		public mounted() {
			this.$watch('language', (lang: string) =>
				this.locale = suggestLocale(lang, this.locale, this.reportSystemOfUnit === 'Imperial'));
		}

		public generateSummary(preview: boolean) {
			if (this.reportType.id === ReportId.ProjectExcel)
				return this.generateExcel();

			const fullProject = !this.singleSizingReport;
			if (this.sizing || fullProject) {
				let sizingIds: Array<{ Ids: string[] }>;
				let filename: string;
				if (fullProject) {
					// Gather sizings per parent and make sure the parent is first in each list
					const allSizings = store.get(SizingGetters.projectSizings, this.project.id) as PumpDocument[];
					const map: { [key: string]: { Ids: string[] } } = {};
					allSizings.forEach(s => {
						const parent = s.ParentId || s.id;
						const slot = map[parent] || (map[parent] = { Ids: [parent] });
						if (s.ParentId)
							slot.Ids.push(s.id);
					});
					sizingIds = Object.values(map);
					filename = this.project.Name;
				} else {
					sizingIds = [{Ids: this.variants.map(x => x?.id)}];
					filename = this.project.Name + '_' + this.sizing.Name;
				}

				if (!sizingIds.length) {
					store.dispatch(SnackActions.set, 'No sizings are available');
					return;
				}

				store.dispatch(SnackActions.set, 'Generating summary report');
				const ri: Partial<ReportInputs> = {
					Sizings: sizingIds,
					ProjectId: this.project.id,
					Filename: 'Summary_' + filename,
					HtmlView: preview || undefined,
					RootUrl: window.location.origin + window.location.pathname
				};
				Object.assign(ri, this.curveSettings || {}, this.settings);

				this.working = true;
				ReportingService.generateSummary(ri as ReportInputs).then(data => {
					const evt = (preview ? 'Report:Preview' : 'Report:CreatePDF') + (fullProject ? 'FullProject' : '');
					InsightService.trackEvent(evt, ri);
					if (preview) {
						this.showPreview = true;
						PerformancePreview(data, 'previewHtml');
					}
				}).catch(() => {
					InsightService.trackEvent('Report:Error', ri);
					store.dispatch(SnackActions.set, 'Unable to generate report. Please contact support.');
				}).finally(() => this.working = false);
			}
			return true;
		}

		public generateExcel() {
			if (!this.project)
				return;

			store.dispatch(SnackActions.set, 'Generating file');
			const sizings = store.get(SizingGetters.projectSizings, this.project.id) as PumpDocument[];
			const ri: ReportInputs = {
				Sizings: [{Ids: sizings.map(x => x.id)}],
				UseImperial: this.reportSystemOfUnit === 'Imperial',
				Filename: this.project.Name
			};
			this.working = true;
			ReportingService.generateProjectExcel(ri).then(data => {
				InsightService.trackEvent('Report:ExcelExport', ri);
			}).catch(() => {
				InsightService.trackEvent('Report:Error', ri);
				store.dispatch(SnackActions.set, 'Unable to generate file. Please contact support.');
			}).finally(() => this.working = false);
		}
	}
</script>
