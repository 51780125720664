import store, { SizingGetters, SizingActions, ProjectMutations, ProjectGetters, ProjectActions } from '@/store';
import { ValidationResult, PumpDocument, ParameterDef, MessageSeverity, PumpDocMetaData } from 'types/dto/CalcServiceDomain';
import { SizingMutations } from '@/store/modules/sizing.store';
import UnitValue from './UnitValue';
import { ParamBag, ValueRef } from './ParamBag';

export interface IParameterStorage {
	metadata: PumpDocMetaData;
	value(): any;
	setValue(value: any, skipSave?: boolean): void;
	messages(): ValidationResult[];
	valueName: string;
}

export class PersistentParameterStorage implements IParameterStorage {
	private readonly sizingId: string;
	public readonly valueName: string;
	public readonly metadata: PumpDocMetaData;
	private readonly target: { container: any, field: string };

	constructor(sizingId: string, valueName: string) {
		this.sizingId = sizingId;
		this.valueName = valueName;

		// Materialize the parameter and store its container for reactivity in value()
		if (this.sizingId) {
			const args = { sizingId: this.sizingId, valueName };
			store.commit(SizingMutations.materializeValue, args);
			this.target = store.get(SizingGetters.getValueRef, args);
			this.metadata = store.get(SizingGetters.sizing, this.sizingId);
		}
	}

	public get value(): any {
		const t = this.target;
		return t?.container?.[t.field] || null;
	}

	public setValue(value: any, skipSave?: boolean): void {
		store.dispatch(SizingActions.setValue, { value, sizingId: this.sizingId, valueName: this.valueName, skipSave });
	}

	public messages(): ValidationResult[] {
		const sizing = store.get(SizingGetters.sizing, this.sizingId) as PumpDocument;
		if (sizing) {
			const errs = sizing.Status;
			return errs?.length && errs.filter(e => e.ParamName === this.valueName) || null;
		}
		return null;
	}
}

// tslint:disable-next-line: max-classes-per-file
export class ProjectParameterStorage implements IParameterStorage {
	private readonly projectId: string;
	public readonly valueName: string;
	public readonly metadata: PumpDocMetaData = null;
	private readonly target: { container: any, field: string };

	constructor(projectId: string = null, valueName: string) {
		this.valueName = valueName;
		this.projectId = projectId;
		const args = { projectId: this.projectId, valueName };
		store.commit(ProjectMutations.materializeValue, args);
		this.target = store.get(ProjectGetters.getValueRef, args);
		this.metadata = { id: projectId } as PumpDocMetaData;
	}

	public get value(): any {
		const t = this.target;
		return t?.container?.[t.field] || null;
	}

	public setValue(value: any, skipSave?: boolean): void {
		store.dispatch(ProjectActions.setValue, { value, projectId: this.projectId, valueName: this.valueName, skipSave });
	}

	public messages(): ValidationResult[] {
		return null;
	}
}

// tslint:disable-next-line: max-classes-per-file
export class MultiWriteParameterStorage extends PersistentParameterStorage {
	private readonly sizingIds: string[];

	constructor(sizingIds: string[], valueName: string) {
		super(sizingIds[0], valueName);
		this.sizingIds = sizingIds;
	}

	public setValue(value: any, skipSave?: boolean): void {
		const batch = this.sizingIds.map(s => ({ value, sizingId: s, valueName: this.valueName }));
		store.dispatch(SizingActions.setValues, batch);
	}

	public messages(): ValidationResult[] {
		const sizings = this.sizingIds.map(id => store.get(SizingGetters.sizing, id) as PumpDocument);
		const allMsgs = sizings.map(x => x?.Status?.filter(e => e.ParamName === this.valueName));
		return ParamBag.mergeMessages(...allMsgs);
	}
}

// tslint:disable-next-line: max-classes-per-file
export class AdHocParameterStorage implements IParameterStorage {
	private readonly container: any;
	private readonly localPropName: string;
	public readonly metadata: PumpDocMetaData;

	constructor(container: any, localPropName: string, metadata?: PumpDocMetaData) {
		this.container = container;
		this.localPropName = localPropName;
		this.metadata = metadata;
	}

	public get value(): any {
		return this.container?.[this.localPropName];
	}

	public get valueName(): string {
		return this.localPropName;
	}

	public setValue(value: any): void {
		if (this.container)
			this.container[this.localPropName] = value;
	}

	public messages(): ValidationResult[] {
		const suffix = '.' + this.localPropName;
		return (this.metadata as PumpDocument)?.Status?.filter(x => x.ParamName?.endsWith(suffix));
	}
}

// tslint:disable-next-line: max-classes-per-file
export class AggregateParameterStorage implements IParameterStorage {
	private readonly targets: ValueRef[];
	private readonly def: ParameterDef;
	public readonly metadata: PumpDocMetaData = null;

	constructor(def: ParameterDef, metadata: PumpDocMetaData, valueFetcher?: (arg: string) => ValueRef) {
		this.def = def;
		this.metadata = metadata;

		if (valueFetcher)
			this.targets = def.Values.map(v => valueFetcher(v.value));
		else
			this.targets = def.Values.map(v => {
				const args = { sizingId: metadata.id, valueName: v.value };
				store.commit(SizingMutations.materializeValue, args);
				return store.get(SizingGetters.getValueRef, args);
			});
	}

	public get valueName(): string {
		return null;
	}

	public get value(): any {
		const vals = (this.targets || []).map(t => t?.container?.[t.field]);
		let res: string = '';
		let lastVal = 0;
		vals.forEach((v, idx) => {
			if (v == null)
				v = this.getDefault(this.def.Values[idx].value);
			if (v != null) {
				if (typeof v === 'number' && this.def.Decimals != null)
					v = UnitValue.round(v, 1, this.def.Decimals);
				res += v;
				lastVal = res.length;
			}
			res += this.def.Values[idx].text || '';
		});
		return lastVal > 0 ? res.substring(0, lastVal) : null;
	}

	public setValue(value: any, skipSave?: boolean): void {
		throw new Error('Method not implemented.');
	}

	public messages(): ValidationResult[] {
		const pnames = this.def.Values.map(x => x.value);
		return (this.metadata as PumpDocument)?.Status?.filter(e => e.ParamName && pnames.includes(e.ParamName)) || null;
	}

	private getDefault(valueName: string) {
		if (!valueName || !this.metadata?.id)
			return;

		// Assumed/calculated values are taken directly from the sizing here. Somewhat hackish.
		const sizing = store.get(SizingGetters.sizing, this.metadata.id) as PumpDocument;
		const deflt = sizing?.Status?.find(x => x.ParamName === valueName && x.Severity === MessageSeverity.UsedValue);
		return deflt?.Message;
	}
}
