import Vue from 'vue';
import { ParameterDef, PumpParams, ValidationResult, MessageSeverity, PumpDocument, PumpProject, PumpDocMetaData } from 'types/dto/CalcServiceDomain';
import { ParamValue } from '@/common/ParamValue';
import { PersistentParameterStorage, AdHocParameterStorage, ProjectParameterStorage, IParameterStorage, MultiWriteParameterStorage, AggregateParameterStorage } from '@/common/ParameterStorage';
import SizingService from '@/services/sizing.service';
import { GroupValidationResult } from '@/common/GroupValidationResult';
import UnitValue from './UnitValue';
import store, { NetworkGetters, AuthGetters, SizingGetters, ProjectGetters } from '@/store';
import { LocalStorageService } from '@/services/localstorage.service';

export interface ValueRef {
	container: any;
	field: string;
}

export class ParamBag {
	private static paramDefs: ParameterDef[];
	private static defMap: { [key: string]: ParameterDef } = {};
	private static paramGroups: ParameterDef[];
	private static paramDefFetcher = new Promise<ParameterDef[]>(() => null);
	private static allValueNames: string[];
	private static visibleValueNames: string[];

	private readonly valueCache: { [key: string]: ParamValue } = {};
	public readonly variants: PumpDocument[] = null;
	private pumpProj: PumpProject = null;
	private readonly persistent: boolean;
	public readonly sizingId: string;

	constructor(variants: PumpDocument[], sizingId?: string, persistent?: boolean) {
		this.persistent = !!persistent;
		this.variants = variants;
		if (this.variants?.length === 1 && !sizingId)
			this.sizingId = variants[0].id;
		else
			this.sizingId = sizingId;
	}

	private static getLocalParamDefs() {
		return LocalStorageService.getItem<ParameterDef[]>('pb').then(p => ParamBag.setDefs(p));
	}

	private static setDefs(defs: ParameterDef[]) {
		ParamBag.paramDefs = defs.filter(x => x.Type !== 'Group');
		ParamBag.paramDefs.forEach(x => ParamBag.defMap[x.Name] = x);
		ParamBag.paramGroups = defs.filter(x => x.Type === 'Group');
		ParamBag.allValueNames = defs.map(x => x.Name);
		ParamBag.visibleValueNames = defs.filter(x => !x.Tags?.includes('Hidden')).map(x => x.Name);
	}

	public static initialize(paramDefs?: ParameterDef[]) {
		if (paramDefs) {
			// Preload for testing
			ParamBag.setDefs(paramDefs);
			return;
		}
		if (!ParamBag.paramDefs) {
			ParamBag.paramDefs = [];
			if (!store.get(NetworkGetters.connected)) {
				console.log('Offline - trying parameter defs in local storage');
				ParamBag.paramDefFetcher = ParamBag.getLocalParamDefs()
					.catch<any>(() => console.error('Offline, but no parameter defs in storage. Failing.'));
			} else {
				ParamBag.paramDefFetcher = SizingService.getParameterDefs().then(p => {
					return SizingService.getProjectParameterDefs().then(pp => {
						p = p.concat(pp);
						LocalStorageService.setItem('pb', p);
						return ParamBag.setDefs(p);
					});
				}).catch<any>(() => {
					console.error('Failed to load parameter defs. Trying local storage version.');
					ParamBag.paramDefFetcher = ParamBag.getLocalParamDefs()
						.catch<any>(() => console.error('No parameter defs available on or offline. Failing.'));
				});
			}
		}
		return ParamBag.paramDefFetcher;
	}

	public static getDefinition(name: string) {
		return ParamBag.paramDefs.find(x => x.Name === name);
	}

	public static get groups() {
		return ParamBag.paramGroups || [];
	}

	public useProject(project: PumpProject) {
		if (this.pumpProj)
			throw new Error('I already have a project');
		this.pumpProj = project;
	}

	public get allValueNames(): string[] {
		if (!this.currentSizing)
			return [];
		return ParamBag.allValueNames;
	}

	public get visibleValueNames(): string[] {
		if (!this.currentSizing)
			return [];
		return ParamBag.visibleValueNames;
	}

	// Names of all parameters contained in an aggregate. I.e. CritSpeedMin/Max.
	public static get aggregatedValueNames(): string[] {
		let aggs: string[] = [];
		ParamBag.paramDefs
			.filter(x => x.Type === 'Aggregate')
			.map(x => x.Values)
			.forEach(v => aggs = aggs.concat(v.map(x => x.value)));
		return aggs;
	}

	public set(valueName: string, value: any, skipSave?: boolean): void {
		const param = this.getParam(valueName);
		if (param)
			this.getParam(valueName).setValue(value, skipSave);
		else
			console.error('set: value ' + valueName + ' is not defined');
	}

	public static getMultiWriteParam(valueName: string, sizingIds: string[]) {
		const storage = new MultiWriteParameterStorage(sizingIds, valueName);
		const pdef = ParamBag.getDef(valueName);
		return new ParamValue(pdef, storage);
	}

	public getParam(valueName: string, explicitDefName?: string): ParamValue {
		if (!valueName) {
			console.error('getParam: no valueName specified');
			return;
		}

		const pdef = ParamBag.getDef(explicitDefName || valueName);
		if (!pdef) {
			console.error('getParam: undefined parameter ' + (explicitDefName || valueName));
			return;
		}

		const pumpProjId: string = this.pumpProj?.id;
		if (pumpProjId)
			return new ParamValue(pdef, new ProjectParameterStorage(pumpProjId, valueName));

		if (!this.persistent && this.valueCache[valueName])
			return this.valueCache[valueName];

		const storage = this.createStorage(pdef, valueName, this.currentSizing);
		if (!storage)
			return;

		let variants: IParameterStorage[];
		if (this.variants?.length)
			variants = this.variants.map(sz => this.createStorage(pdef, valueName, sz));

		const param = new ParamValue(pdef, storage, variants);
		if (!this.persistent)
			Vue.set(this.valueCache, valueName, param);
		return param;
	}

	private static getDef(name: string) {
		return ParamBag.defMap[name];
	}

	public get currentSizing() {
		if (this.variants?.length === 1)
			return this.variants[0];

		const curId = this.sizingId;
		if (!curId)
			throw new Error('There is no current sizing');
		return curId && this.variants?.find(x => x.id === curId) || null;
	}

	private createStorage(pdef: ParameterDef, valueName: string, fromDoc: PumpDocument) {
		let storage: IParameterStorage;

		if (this.persistent) {
			if (pdef.Type === 'Aggregate')
				storage = new AggregateParameterStorage(pdef, fromDoc);
			else
				storage = new PersistentParameterStorage(fromDoc?.id, valueName);
		} else {
			const data = fromDoc?.Data;
			if (pdef.Type === 'Aggregate') {
				const valueFetcher = (name: string) => ParamBag.getParamField(data, name);
				storage = new AggregateParameterStorage(pdef, fromDoc, valueFetcher);
			} else {
				const target = ParamBag.getParamField(data, valueName);
				if (target)
					storage = new AdHocParameterStorage(target.container, target.field, fromDoc);
			}
		}
		return storage;
	}

	public createValue(valueName: string, value: any, imperial: boolean): UnitValue {
		const param = this.valueCache[valueName];
		const pdef = param?.definition || ParamBag.getDef(valueName);
		if (!pdef)
			return null;
		const unitId = (imperial && pdef.PrefImperialUnit) || pdef.PreferredUnit || pdef.Unit;
		return pdef && new UnitValue(value, unitId, pdef.Decimals, imperial) || null;
	}

	public get<T>(valueName: string, explicitDefName?: string): T {
		const param = this.getParam(valueName, explicitDefName);
		return param?.getValue() as any as T;
	}

	public getNumericValue(valueName: string, explicitDefName?: string): number {
		const val = this.get<any>(valueName, explicitDefName);
		return val && UnitValue.asNumber(val) || null;
	}

	public errorsInGroup(prefix: string, ignorePrefix?: string, singleDuty?: boolean): GroupValidationResult {
		if (!prefix || !this.variants)
			return new GroupValidationResult([]);

		let allMsgs;
		if (singleDuty)
			allMsgs = this.currentSizing?.Status?.filter(x => x.ParamName?.startsWith(prefix));
		else
			allMsgs = this.variants?.map(v => v.Status?.filter(x => x.ParamName?.startsWith(prefix))).flat();

		if (ignorePrefix && allMsgs)
			allMsgs = allMsgs.filter(x => !x.ParamName.startsWith(ignorePrefix));
		return new GroupValidationResult(ParamBag.mergeMessages(allMsgs));
	}

	public hasProblem(valueName: string) {
		if (!valueName || !this.variants)
			return false;
		return this.variants?.some(v => v.Status?.some(x => x.ParamName === valueName && x.Severity >= MessageSeverity.Warning));
	}

	public static mergeMessages(...msgs: ValidationResult[][]) {
		const found: any = {};
		msgs?.forEach(v => v?.forEach(s => found[s.Message + s.ParamName] = s));
		return Object.values(found) as ValidationResult[];
	}

	public createVariants(variants: Array<{ values: Partial<PumpParams>, messages: ValidationResult[] }>, includeAllData?: boolean) {
		let sizingVariants = this.variants || [];
		if (variants?.length < 1) {
			console.error('No variants supplied');
			return this;
		} else if (variants.length !== sizingVariants?.length) {
			console.debug('Variant collection mismatch');
			if (variants.length < sizingVariants.length) {
				console.warn('Clipping sizing duty points to match passed variants length');
				sizingVariants = sizingVariants.slice(0, variants.length);
			}
		}

		const docs = sizingVariants.map((orig, idx) => {
			const variant = variants[idx];
			let data: any;
			if (includeAllData)
				data = Object.assign({}, orig.Data || {}, variant.values);
			else
				data = variant.values;
			const patched: Partial<PumpDocument> = { Data: data || {} as PumpParams, Status: variant.messages };
			return Object.assign({}, orig, patched) as PumpDocument;
		});

		return new ParamBag(docs, this.sizingId);
	}

	public static getParamField(container: any, n: string, createPath: boolean = false): ValueRef {
		if (!container)
			return;

		// Split all '.' and '[]' expressions into a list of parts (a.b[2].c.d -> a, b, 2, c, d)
		const parts = n.split(/[\.\[\]]/).filter(x => x?.length);
		const containers = parts.slice(0, -1);
		for (let i = 0; i < containers.length; i++) {
			const part = containers[i];
			let newContainer = container[part];
			if (!newContainer) {
				// Read only - bail out if container is missing
				if (!createPath)
					return null;

				// Create path to container in place
				if (i < containers.length - 1 && /[0-9]+/.test(containers[i + 1]))
					newContainer = Vue.set(container, part, []);
				else
					newContainer = Vue.set(container, part, {});
			}
			container = newContainer;
		}
		const field = parts[parts.length - 1];
		return { container, field };
	}

	public static useImperial(id: string) {
		let sou: string;
		if (id)
			sou = ParamBag.getSizingSOU(id);
		if (!sou && id)
			sou = ParamBag.getProjectSOU(id);
		if (!sou)
			sou = store.get(AuthGetters.systemOfUnit);
		return sou === 'Imperial';
	}

	private static getProjectSOU(projectId: string): 'Imperial' | 'Metric' {
		const proj = projectId && store.get(ProjectGetters.project, projectId) as PumpProject;
		return proj?.Site?.SystemOfUnits;
	}

	public static getSizingSOU(sizingId: string) {
		if (sizingId) {
			const sz = store.get(SizingGetters.sizing, sizingId) as PumpDocMetaData;
			if (sz)
				return ParamBag.getProjectSOU(sz.ProjectId);
		}
	}
}
