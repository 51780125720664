import { PumpDocument, StagingDefinition, ParentRelation, PumpDocMetaData, DocState } from 'types/dto/CalcServiceDomain';
import SizingService from '@/services/sizing.service';
import Vue from 'vue';
import { ParamBag, ValueRef } from '@/common/ParamBag';
import { LocalStorageService } from '@/services/localstorage.service';
import store, { AuthGetters, NetworkGetters, SnackActions } from '@/store';
import { ActionContext } from 'vuex';
import BuildManager from '@/common/BuildManager';
import SizingInfo from '@/common/SizingInfo';
import InsightService from '@/services/insight.service';
import { canonicalText, merge } from '@/common/Tools';
import WorkQueue from '@/common/WorkQueue';

interface IStoredSizing {
	readonly sizing: PumpDocument;
	loading: boolean;
	isDirty: boolean;
	fullyLoaded: boolean;
}

interface ISizingState {
	readonly sizings: { [id: string]: IStoredSizing };
	loading: boolean;
	synchronizing: boolean;
}

const sizingState: ISizingState = {
	sizings: {},
	loading: false,
	synchronizing: false
};

type StoreActionContext = ActionContext<ISizingState, ISizingState>;

interface NamedSizingValue {
	sizingId: string;
	valueName: string;
	value?: any;
}

interface UpdateItem {
	sizing: PumpDocument;
	target?: string;
	newValue?: string;
}

const workQueues: { [id: string]: WorkQueue } = {};

const offline = () => !store.get(NetworkGetters.connected);
const userId = () => store.get(AuthGetters.userId);

const getters = {
	allSizings: (state: ISizingState) => Object.values(state.sizings).map(x => x.sizing),
	projectSizings: (state: ISizingState) => (id: string) => Object.values(state.sizings).map(x => x.sizing).filter(s => s.ProjectId === id),
	sizing: (state: ISizingState) => (id: string) => state.sizings[id]?.sizing,
	sizings: (state: ISizingState) => (ids: string[]) => ids.map(id => state.sizings[id]?.sizing).filter(sz => sz && sz.DocState !== DocState.Deleted),
	childSizings: (state: ISizingState) => (id: string) => Object.values(state.sizings).map(x => x.sizing).filter(s => s.ParentId === id),
	isDirty: (state: ISizingState) => (id: string) => state.sizings[id]?.isDirty || false,
	isAssumed: (state: ISizingState) => ({ id, valueName }: { id: string, valueName: string }) =>
		state.sizings[id]?.sizing?.Assumed?.includes(valueName) || false,
	hasDirtySizings: (state: ISizingState) => (id: string) => Object.values(state.sizings).filter(s => s.sizing.ProjectId === id && s.isDirty).length > 0,
	loadingSizing: (state: ISizingState) => (id: string) => state.sizings[id]?.loading || false,
	fullyLoaded: (state: ISizingState) => ({ id, skipChildren = true }: { id: string, skipChildren: boolean }) => {
		const sz = state.sizings[id];
		if (!sz || !sz.fullyLoaded || sz.loading)
			return false;
		if (skipChildren || !sz.sizing.MDP && !sz.sizing.Staged)
			return true;
		const all = (getters.allSizings(state) || []) as PumpDocument[];
		return !all.some(x => x.ParentId === id && !state.sizings[x.id].fullyLoaded);
	},
	getValueRef: (state: ISizingState) => ({ sizingId, valueName }: NamedSizingValue): ValueRef => {
		const s = state.sizings[sizingId];
		if (s) {
			if (!s.sizing || !s.sizing.Data) {
				console.error(`getValueRef: sizing was not fully loaded when accessing ${sizingId}.${valueName}`);
				return null;
			}
		} else {
			console.debug(`getValueRef: sizing ${sizingId} does not exist`);
			return null;
		}
		return ParamBag.getParamField(s.sizing.Data, valueName, false);
	},
	loading: (state: ISizingState) => state.loading || false,
	synchronizing: (state: ISizingState) => state.synchronizing || false,
	workQueue: () => (id: string) => workQueues[id] = workQueues[id] || new WorkQueue()
};

const actions = {
	async clone({ commit, dispatch }: StoreActionContext, { clone, name, targetProjectId }: { clone: PumpDocument, name: string, targetProjectId: string }) {
		if (offline()) {
			store.dispatch(SnackActions.set, `Unable to copy sizings when offline`);
			return null;
		}

		try {
			InsightService.trackEvent('Sizing:Clone');
			commit('startLoading');
			const data = await SizingService.clone(clone, name, targetProjectId);
			await dispatch('addSizings', data);
			return data[0];
		} catch (e) {
			console.error('Unable to clone sizing', e);
			return Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async getSizing({ commit, state }: StoreActionContext, id: string) {
		if (offline() || getters.isDirty(state)(id)) {
			const s = await LocalStorageService.getItem<IStoredSizing>('s:' + id);
			if (!s) {
				store.dispatch(SnackActions.set, `Sizing is not downloaded for offline use`);
				return null;
			}
			commit('add', { siz: s.sizing, uid: userId() });
			return s.sizing;
		}

		try {
			commit('startLoading');
			commit('startLoadingSizing', id);
			const data = await SizingService.fetchSizing(id);
			commit('add', { siz: data, fullyLoaded: true, uid: userId() });
			return data;
		} catch (e) {
			console.error('Unable to get sizing', e);
			return Promise.reject(e);
		} finally {
			commit('finishLoading');
			commit('finishLoadingSizing', id);
		}
	},
	async getSizings({ commit, state }: StoreActionContext, id: string) {
		const uid = userId();
		if (offline() || getters.hasDirtySizings(state)(id)) {
			const sizings = await LocalStorageService.startsWith('s:');
			if (!sizings || !Object.keys(sizings).length) {
				store.dispatch(SnackActions.set, `Sizings are not downloaded for offline use`);
				return [];
			}
			const data = Object.values(sizings);
			data.forEach((s: IStoredSizing) => {
				// If sizing is already in memory with changes, don't clobber it from local storage
				if (!getters.isDirty(state)(s.sizing.id))
					commit('add', { siz: s.sizing, uid, fullyLoaded: !!s.fullyLoaded });
			});
			return data.map((s: IStoredSizing) => s.sizing);
		}

		try {
			commit('startLoading');
			const oldIds = getters.projectSizings(state)(id).map(x => x.id);
			const data = await SizingService.getProjectSizings(id);
			const newIds: string[] = [];
			data.forEach((s: PumpDocMetaData) => { commit('add', { siz: s, uid }); newIds.push(s.id); });

			// Purge any removed sizings
			oldIds.forEach(oldId => {
				if (!newIds.includes(oldId))
					commit('remove', oldId);
			});
			return data;
		} catch (e) {
			console.error('Unable to get sizings', e);
			return Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async update({ dispatch, commit }: StoreActionContext, batch: UpdateItem[]): Promise<PumpDocument[]> {
		if (!batch)
			return;
		if (!Array.isArray(batch))
			batch = [batch];
		if (!batch?.length)
			return;

		if (offline()) {
			const uid = userId();
			batch.forEach(x => commit('add', { siz: x.sizing, uid }));
			return batch.map(x => x.sizing);
		}

		try {
			commit('startLoading');
			const jobId = `Update ${batch.map(x => x.sizing.id).join(',')}`;
			const wq = getters.workQueue()(batch[0].sizing.id);
			const data = await wq.replace(jobId, () => SizingService.update(batch));
			if (data)
				await dispatch('addSizings', data);
			batch.forEach(x => commit('clean', x.sizing.id));
			return data;
		} catch (e) {
			console.error('Unable to update sizing', e);
			return Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async create({ dispatch, commit, state }: StoreActionContext, sz: PumpDocument): Promise<PumpDocument[]> {
		InsightService.trackEvent('Sizing:Create');
		return actions.update({ dispatch, commit, state } as StoreActionContext, [{ sizing: sz }]);
	},
	async stage({ dispatch, commit }: StoreActionContext, { id, def }: any) {
		if (offline()) {
			store.dispatch(SnackActions.set, `Unable to stage sizings when offline`);
			return null;
		}

		try {
			InsightService.trackEvent('Sizing:Stage');
			commit('startLoading');
			const data = await SizingService.stage(id, { Stages: def } as StagingDefinition);
			dispatch('addSizings', data);
			return data;
		} catch (e) {
			console.error('Unable to stage sizing', e);
			return Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async unstage({ commit, state }: StoreActionContext, id: string) {
		if (offline()) {
			store.dispatch(SnackActions.set, `Unable to unstage sizings when offline`);
			return null;
		}

		try {
			commit('startLoading');
			const data = await SizingService.unstage(id);
			commit('add', { siz: data, uid: userId() });
			getters.allSizings(state).forEach(sz => {
				if (sz.ParentId === id && sz.ParentRelation === ParentRelation.Stage)
					commit('remove', sz.id);
			});
			return data;
		} catch (e) {
			console.error('Unable to unstage sizing', e);
			return Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async addDutyPoint({ dispatch, commit }: StoreActionContext, id: string) {
		if (offline()) {
			store.dispatch(SnackActions.set, `Unable to modify dutypoints when offline`);
			return null;
		}

		try {
			InsightService.trackEvent('Sizing:AddDutyPoint');
			commit('startLoading');
			const data = await SizingService.addDutyPoint(id);
			dispatch('addSizings', data);
			return data?.length ? data[data.length - 1] : null;
		} catch (e) {
			console.error('Unable to modify dutypoints', e);
			return Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async removeDutyPoint({ state, commit }: StoreActionContext, id: string) {
		if (offline()) {
			store.dispatch(SnackActions.set, `Unable to modify dutypoints when offline`);
			return null;
		}

		const victim = state.sizings[id]?.sizing;
		if (!victim || victim.DocState === DocState.Deleted)
			throw new Error('Duty point to remove does not exist');
		const oldState = victim.DocState;

		try {
			// Mark as removed while calling backend
			victim.DocState = DocState.Deleted;
			commit('startLoading');
			const data = await SizingService.removeDutyPoint(id);
			commit('remove', id);
			data?.forEach(x => commit('add', { siz: x, uid: userId() }));
			return data;
		} catch (e) {
			victim.DocState = oldState;
			console.error('Unable to modify dutypoints', e);
			return Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async setValue({ dispatch, commit, state }: StoreActionContext, { value, sizingId, valueName, skipSave }: NamedSizingValue & { skipSave?: boolean }) {
		commit('setValue', { value, sizingId, valueName });
		const s = state.sizings[sizingId];
		if (skipSave || !s.isDirty)
			return s.sizing;
		const newValue = value != null && typeof(value) !== 'object' && value.toString() || null;
		return await dispatch('update', { sizing: s.sizing, target: valueName, newValue } as UpdateItem);
	},
	async setValues({ dispatch, commit, state }: StoreActionContext, batch: Array<{ sizingId: string, valueName: string, value: any, skipSave?: boolean }>) {
		if (!batch?.length)
			return;
		const root = state.sizings[batch[0].sizingId];
		if (!root?.sizing || !root.fullyLoaded) {
			console.error('Missing sizing in batch');
			return;
		}

		const changed = [];
		for (const x of batch) {
			commit('setValue', x);
			if (!x.skipSave && state.sizings[x.sizingId]?.isDirty)
				changed.push(x);
		}
		if (changed.length) {
			const payload = changed.map(x => {
				const newValue = x.value != null && typeof(x.value) !== 'object' && x.value.toString() || null;
				return { sizing: state.sizings[x.sizingId]?.sizing, target: x.valueName, newValue } as UpdateItem;
			});
			return await dispatch('update', payload);
		}
	},
	addSizing({ commit }: StoreActionContext, sizingToAdd: PumpDocument) {
		commit('add', { siz: sizingToAdd, uid: userId() });
	},
	addSizings({ commit }: StoreActionContext, sizingsToAdd: PumpDocument[]) {
		if (sizingsToAdd) {
			const uid = userId();
			sizingsToAdd.forEach(s => commit('add', { siz: s, uid }));
		}
	},
	async removeSizing({ commit }: StoreActionContext, id: string) {
		if (offline()) {
			store.dispatch(SnackActions.set, 'Unable to remove sizings when offline');
			return true;
		}
		try {
			InsightService.trackEvent('Sizing:Delete');
			commit('startLoading');
			await SizingService.delete(id);
			commit('remove', id);
		} catch (e) {
			console.error('Unable to remove sizing', e);
			return Promise.reject(e);
		} finally {
			commit('finishLoading');
		}
	},
	async updateSection({ dispatch, state, commit }: StoreActionContext, { sizingId, sections, target, newValue, skipSave }:
		{ sizingId: string, sections: any, target?: string, newValue?: string, skipSave?: boolean }): Promise<PumpDocument> {
		const s = state.sizings[sizingId];
		if (!s)
			return;
		commit('mergeValues', { sizingId, values: sections });
		if (skipSave || !s.isDirty)
			return s.sizing;
		return await dispatch('update', { sizing: s.sizing, target, newValue } as UpdateItem);
	},
	async updateSections({ dispatch, state, commit }: StoreActionContext, batch: Array<{ sizingId: string, sections: any, target?: string, newValue?: string }>): Promise<any> {
		if (!batch?.length)
			return;
		const root = state.sizings[batch[0].sizingId];
		if (!root?.sizing || !root.fullyLoaded) {
			console.error('Missing sizing in batch');
			return;
		}

		const changed = [];
		for (const x of batch) {
			commit('mergeValues', { sizingId: x.sizingId, values: x.sections });
			if (state.sizings[x.sizingId]?.isDirty)
				changed.push(x);
		}
		if (changed.length) {
			const payload = changed.map(x => ({ sizing: state.sizings[x.sizingId]?.sizing, target: x.target, newValue: x.newValue }));
			return await dispatch('update', payload as UpdateItem[]);
		}
	},
	async updateDirtySizings({ dispatch, commit }: StoreActionContext) {
		if (offline()) {
			store.dispatch(SnackActions.set, 'Unable to update sizings when offline');
			return;
		}
		const data = await LocalStorageService.startsWith('s:') as IStoredSizing[];
		if (!data || !Object.keys(data).length)
			return;

		const sizings = Object.values(data).filter(s => s.isDirty && !SizingInfo.isLocked(s.sizing));
		if (sizings?.length) {
			try {
				commit('synchronizing', true);
				InsightService.trackEvent('Sizing:OfflineSync', { dirtyCount: sizings.length });
				let idx = 1;
				for (const s of sizings) {
					store.dispatch(SnackActions.set, `Synchronizing sizing ${idx}/${sizings.length}`);
					commit('startLoadingSizing', s.sizing.id);

					try {
						await dispatch('update', { sizing: s.sizing, target: 'OfflineSync' } as UpdateItem);
						await BuildManager.recalc(s.sizing);
					} finally {
						commit('finishLoadingSizing', s.sizing.id);
					}
					++idx;
				}
			} finally {
				commit('synchronizing', false);
			}
		}
	},
	async rename({ dispatch, commit, state }: StoreActionContext, { sizingId, name }: { sizingId: string, name: string }) {
		const s = state.sizings[sizingId];
		if (!s)
			return;
		// Must not clear the name of a sizing with children since the parent name is what you see
		if (!s.sizing.ParentId && (!name || !name.trim().length))
			return;
		commit('setMeta', { sizingId, valueName: 'Name', value: name } as NamedSizingValue);
		return await dispatch('update', { sizing: s.sizing, target: 'Sizing.Name', newValue: name } as UpdateItem);
	},
	async setSubtitle({ dispatch, commit, state }: StoreActionContext, { sizingId, name }: { sizingId: string, name: string }) {
		const s = state.sizings[sizingId];
		if (!s)
			return;
		commit('setMeta', { sizingId, valueName: 'Subtitle', value: name } as NamedSizingValue);
		return await dispatch('update', { sizing: s.sizing, target: 'Sizing.Subtitle', newValue: name } as UpdateItem);
	},
	async setNote({ dispatch, commit, state }: StoreActionContext, { sizingId, text }: { sizingId: string, text: string }) {
		const s = state.sizings[sizingId];
		if (!s)
			return;
		commit('setMeta', { sizingId, valueName: 'Notes', value: text } as NamedSizingValue);
		return await dispatch('update', { sizing: s.sizing, target: 'Sizing.Notes' } as UpdateItem);
	},
	async setAssumed({ dispatch, commit, state }: StoreActionContext, { sizingId, valueName, value }: NamedSizingValue) {
		const s = state.sizings[sizingId];
		if (!s || !s.fullyLoaded)
			return;

		const oldVal = s.sizing.Assumed?.includes(valueName);
		if (!!oldVal === !!value)
			return;

		let newList = s.sizing.Assumed;
		if (!value)
			newList = newList?.filter(x => x !== valueName);
		else
			newList = (newList || []).concat([valueName]);
		commit('setMeta', { sizingId, valueName: 'Assumed', value: newList } as NamedSizingValue);
		return await dispatch('update', { sizing: s.sizing, target: 'Sizing.Assumed' } as UpdateItem);
	},
	async clearAssumed({ commit, state }: StoreActionContext, { sizingId, prefix }: { sizingId: string, prefix: string }) {
		const s = state.sizings[sizingId];
		if (!s?.fullyLoaded || !s.sizing.Assumed?.length)
			return;

		const newList = s.sizing.Assumed.filter(x => !x.startsWith(prefix));
		if (newList.length !== s.sizing.Assumed.length)
			commit('setMeta', { sizingId, valueName: 'Assumed', value: newList } as NamedSizingValue);
	}
};

const mutations = {
	startLoading: (targetState: ISizingState) => targetState.loading = true,
	finishLoading: (targetState: ISizingState) => targetState.loading = false,
	startLoadingSizing: (targetState: ISizingState, id: string) => targetState.sizings[id] && (targetState.sizings[id].loading = true),
	finishLoadingSizing: (targetState: ISizingState, id: string) => targetState.sizings[id] && (targetState.sizings[id].loading = false),
	synchronizing: (targetState: ISizingState, value: boolean) => targetState.synchronizing = value,
	add(targetState: ISizingState, { siz, fullyLoaded = false, uid }: { siz: PumpDocument, fullyLoaded: boolean, uid: string }) {
		if (!siz)
			return;
		let stored = targetState.sizings[siz.id];
		if (stored?.sizing) {
			// If someone passed in a "project sizing" after a real sizing was loaded, keep Data/Status...
			siz.Data = siz.Data || stored.sizing.Data;
			siz.Status = siz.Status || stored.sizing.Status;
			siz.Assumed = siz.Assumed || stored.sizing.Assumed;
			stored.fullyLoaded = fullyLoaded || stored.fullyLoaded;
			merge(siz, stored.sizing);
		} else {
			// New sizing - make sure members are reactive if loading them later
			stored = Vue.set(targetState.sizings, siz.id, { sizing: siz, loading: false, isDirty: false, fullyLoaded: fullyLoaded || false });
			Vue.set(stored.sizing, 'Status', siz.Status || []);
			Vue.set(stored.sizing, 'Data', siz.Data || {});
			Vue.set(stored.sizing, 'Assumed', siz.Assumed || []);
		}
		// Note: this blocks guest_user, but since it should only do setValue for now, we are good... :-|
		if (uid == null || !SizingInfo.isLocked(siz))
			LocalStorageService.setItem('s:' + siz.id, stored);
	},
	remove(targetState: ISizingState, id: string) {
		Vue.delete(targetState.sizings, id);
		LocalStorageService.removeItem('s:' + id);
	},
	setValue(targetState: ISizingState, { value, sizingId, valueName }: NamedSizingValue) {
		const s = targetState.sizings[sizingId];
		const pf = ParamBag.getParamField(s.sizing.Data, valueName, true);
		if (pf?.container) {
			const oldVal = pf.container[pf.field];
			// tslint:disable-next-line: triple-equals
			if (value != oldVal) {
				Vue.set(pf.container, pf.field, value);
				if (typeof value === 'object' && oldVal != null)
					s.isDirty = canonicalText(oldVal) != canonicalText(value);
				else
					s.isDirty = true;
			}
		}
		LocalStorageService.setItem('s:' + sizingId, s);
	},
	setMeta(targetState: ISizingState, { value, sizingId, valueName }: NamedSizingValue) {
		const s = targetState.sizings[sizingId];
		if (!s)
			return;
		const oldVal = (s.sizing as any)[valueName];
		// tslint:disable-next-line: triple-equals
		if (value != oldVal) {
			Vue.set(s.sizing, valueName, value);
			s.isDirty = true;
			LocalStorageService.setItem('s:' + sizingId, s);
		}
	},
	clean(targetState: ISizingState, id: string) {
		const s = targetState.sizings[id];
		if (s)
			s.isDirty = false;
	},
	materializeValue(targetState: ISizingState, { sizingId, valueName }: NamedSizingValue) {
		const s = targetState.sizings[sizingId];
		if (s) {
			const pf = ParamBag.getParamField(s.sizing.Data, valueName, true);
			if (!pf)
				console.error(`materializeValue: failed to materialize ${sizingId}.${valueName}`);
		} else
			console.debug(`materializeValue: sizing ${sizingId} does not exist`);
	},
	mergeValues(targetState: ISizingState, { sizingId, values }: { sizingId: string, values: any }) {
		const s = targetState.sizings[sizingId];
		// keepOrphans=true: We don't want to delete all non-specified sections; just update those passed in
		s.isDirty = merge(values, s.sizing.Data, true);
	}
};

export const enum SizingMutations {
	materializeValue = 'sizing/materializeValue',
	purgeSizing = 'sizing/clean'
}

export const enum SizingActions {
	getSizing = 'sizing/getSizing',
	getSizings = 'sizing/getSizings',
	removeSizing = 'sizing/removeSizing',
	clone = 'sizing/clone',
	create = 'sizing/create',
	update = 'sizing/update',
	stage = 'sizing/stage',
	unstage = 'sizing/unstage',
	addDutyPoint = 'sizing/addDutyPoint',
	removeDutyPoint = 'sizing/removeDutyPoint',
	setValue = 'sizing/setValue',
	setValues = 'sizing/setValues',
	updateSection = 'sizing/updateSection',
	updateSections = 'sizing/updateSections',
	updateDirtySizings = 'sizing/updateDirtySizings',
	rename = 'sizing/rename',
	setSubtitle = 'sizing/setSubtitle',
	setNote = 'sizing/setNote',
	setAssumed = 'sizing/setAssumed',
	clearAssumed = 'sizing/clearAssumed',
}

export const enum SizingGetters {
	sizing = 'sizing/sizing',
	sizings = 'sizing/sizings',
	projectSizings = 'sizing/projectSizings',
	childSizings = 'sizing/childSizings',
	loading = 'sizing/loading',
	synchronizing = 'sizing/synchronizing',
	isDirty = 'sizing/isDirty',
	fullyLoaded = 'sizing/fullyLoaded',
	getValueRef = 'sizing/getValueRef',
	loadingSizing = 'sizing/loadingSizing',
	isAssumed = 'sizing/isAssumed'
}

export const sizing = {
	namespaced: true,
	state: sizingState as any,
	getters,
	actions: actions as any,
	mutations
};
