import { DutyPoints } from '@/common/DutyPoints';
import PumpManager from '@/common/PumpManager';
import { ParamBag } from '@/common/ParamBag';
import { BearingAssemblyResult, BuildInputs, DriveArrangement, UsablePumpFrame, DriveParams, MotorResult, FlangeDef, DriveDutyPoint } from 'types/dto/PumpBuild';
import { PumpResult, PumpType, MDPPumpResult, PumpStatus } from 'types/dto/PumpSearch';
import PumpService, { RecalcResult } from '@/services/pumps.service';
import store, { DebugActions, AuthGetters, SizingActions, NetworkGetters, SizingGetters, SnackActions } from '@/store';
import { ValidationResult, PumpDocument, TDHMode, MessageSeverity, PumpParams } from 'types/dto/CalcServiceDomain';
import { VBeltResult, VBeltInputs, VBeltDutyPoint } from 'types/dto/VBelt';
import { BuildParams, PumpBuildOptions } from 'types/dto/PumpBuild';
import { ShaftSealResult } from 'types/dto/PumpBuild';
import { compareDocs, ConflictEvent, sendConflictEvent, compareStatuses } from './compareDocs';
import SizingInfo from './SizingInfo';

export default class BuildManager {
	private dutyPoints: DutyPoints = null;
	private pumpManager: PumpManager = null;
	private values: ParamBag = null;
	private loadedPumpDataId: string = null;
	private pumpDataFetcher: Promise<any> = null;

	// Exposed collections for current sizing
	public availableFrames: UsablePumpFrame[] = [];
	public availableFlanges: FlangeDef[] = [];
	public availableBAs: BearingAssemblyResult[] = [];
	public availableDAs: DriveArrangement[] = null;
	public availableMotors: MotorResult[] = [];
	public availableVBelts: VBeltResult[] = [];
	public availableSeals: ShaftSealResult[] = [];

	public vBeltMessages: ValidationResult[] = [];

	public load(values: ParamBag, dps: DutyPoints, checkPumpChanges: boolean) {
		this.values = values;
		this.dutyPoints = dps;
		this.pumpManager = new PumpManager((dps?.asSizings || []).map(x => x.id));

		// Set initial collections to selected values (which remain until online and server calls are ready)
		if (this.selectedMotor)
			this.availableMotors = [this.selectedMotor];

		if (this.selectedDA)
			this.availableDAs = [this.selectedDA];

		if (this.selectedBA)
			this.availableBAs = [this.selectedBA];

		if (this.selectedVBelt)
			this.availableVBelts = [this.selectedVBelt];

		if (this.selectedFrame)
			this.availableFrames = [this.selectedFrame];

		if (this.selectedFlange)
			this.availableFlanges = [this.selectedFlange];

		if (this.selectedSeal)
			this.availableSeals = [this.selectedSeal];

		if (checkPumpChanges && !this.offline && this.selectedPump && !store.get(SizingGetters.synchronizing)) {
			// Check for reasons to search and replace current pump with a fresh copy (i.e. migrate it)
			if (!this.selectedPump.InletDiameter && this.values?.get<TDHMode>('Heads.TDHMode') === TDHMode.Calculate) {
				if (!dps?.asSizings?.some(x => x.Staged)) {
					store.dispatch(SnackActions.set, 'Pump data is updated to allow for taper pieces. Please check piping.');
					this.updatePump();
				} else
					store.dispatch(SnackActions.set, 'Pump has no inlet diameter. If taper pieces are needed, please re-stage it.');
			} else
				this.checkPumpChanges();
		}
	}

	public get sizingId() {
		return this.values.sizingId;
	}

	public get offline() {
		return !store.get(NetworkGetters.connected);
	}

	public get isMDP() {
		return this.dutyPoints?.isMDP || false;
	}

	public get useVFD() {
		return !!this.values.getParam('Drive.UseVFD').getValue();
	}

	public get selectedPump() {
		return this.pumpManager?.selected;
	}

	public get pumpDutyString() {
		return this.pumpManager?.pumpDutyString;
	}

	public async selectPump(fullPump: MDPPumpResult, extraProps?: any) {
		const oldPump = this.selectedPump;
		const pump = fullPump?.DutyPoints[0];
		if (PumpManager.isSamePump(pump, oldPump))
			return;

		// Clean up build options while waiting for new results from writeSelectedPumps (ELIGO-1471)
		this.availableFrames = [];
		this.availableFlanges = [];
		await this.pumpManager.writeSelected(fullPump, extraProps);
	}

	public get selectedFrame() {
		const frame = this.values?.get<UsablePumpFrame>('Frame');
		return (frame?.id) ? frame : null;
	}

	public get selectedFlange() {
		const flange = this.values?.get<FlangeDef>('Flange');
		return (flange?.id) ? flange : null;
	}

	public get selectedSeal() {
		const seal = this.values?.get<ShaftSealResult>('ShaftSeal');
		return (seal?.id) ? seal : null;
	}

	public set selectedSeal(seal: ShaftSealResult) {
		const batch = this.dutyPoints.asSizings.map((dp, idx) => {
			const dpSeal = seal && Object.assign({}, seal, seal.DutyPoints?.[idx] || {});
			// Don't save all duty points in each duty point...
			if (dpSeal)
				dpSeal.DutyPoints = undefined;
			return { sizingId: dp.id, sections: { ShaftSeal: dpSeal || {} }, target: 'ShaftSeal', newValue: dpSeal?.Description };
		});
		store.dispatch(SizingActions.updateSections, batch);
	}

	public async searchPumps(statuses?: PumpStatus[], includeUnsuitable?: boolean, includeWacko?: boolean): Promise<MDPPumpResult[]> {
		return await this.pumpManager.search(statuses, includeUnsuitable, includeWacko);
	}

	public async updatePump() {
		return this.pumpManager.updateSelected();
	}

	public async checkPumpChanges() {
		const pumpId = this.pumpManager.selected?.Id;
		if (!pumpId)
			return;
		const filter = this.pumpManager.selectedPumpFilter;
		if (!filter?.length)
			return;

		const res = await PumpService.getPumps(filter, false, false);
		if (res?.length && res[0].DutyPoints?.length)
			store.dispatch(DebugActions.replace, { category: 'Pump', entries: res[0].DutyPoints[0].Messages });

		const newVersion = res?.length && res[0];
		if (!newVersion?.DutyPoints?.length || newVersion.DutyPoints[0].Status === PumpStatus.Unavailable) {
			// Pump disappeared completely! Show dialog with sad emoji?
			return;
		}

		if (this.dutyPoints.asSizings?.length !== newVersion.DutyPoints?.length) {
			console.error('Wrong # of duty points returned?!');
			return;
		}

		// Canonicalize sizings and pumpresult to a common PumpDocument for comparison
		const mapToDoc = (idsuffix: string, x: PumpResult, doc: PumpDocument, status: ValidationResult[]) => ({
			id: doc.id + '_' + idsuffix,
			Subtitle: SizingInfo.subtitle(doc),
			Data: { Pump: PumpManager.canonicalizeResult(x) } as PumpParams,
			Status: status
		} as PumpDocument);

		const oldDps = this.dutyPoints.asSizings.map(x => mapToDoc('old', x.Data?.Pump, x, x.Status));
		const newDps = newVersion.DutyPoints.map((x, idx) => mapToDoc('new', x, oldDps[idx], x.Messages));

		const diffNames = compareDocs(oldDps, newDps, 'Pump.');
		const diffMsgs = compareStatuses(oldDps, newDps);
		if (diffNames?.length || diffMsgs?.length) {
			const conflict: ConflictEvent = { me: oldDps, other: newDps, mode: 'Update', changedNames: diffNames,
				changedMsgs: diffMsgs, onAccept: () => this.updatePump() };
			sendConflictEvent(conflict);
		}
	}

	public get buildOptionsFilter() {
		const pump = this.selectedPump;
		if (!pump || !pump.Id || !pump.UsableFrames)
			return null;
		return { UsableFrames: pump.UsableFrames, UsableFlanges: pump.UsableFlanges } as BuildParams;
	}

	public searchBuildOptions() {
		if (this.offline)
			return;

		const bp = this.buildOptionsFilter;
		if (!bp) {
			this.availableFrames = [];
			this.availableFlanges = [];
			return;
		}

		return PumpService.getBuildOptions(bp).then((res: PumpBuildOptions) => {
			this.availableFrames = res.UsableFrames || [];
			this.availableFlanges = res.UsableFlanges || [];
		});
	}

	public async doSearchSeals() {
		if (this.offline)
			return;

		const bi = this.bearingAssemblyFilter;
		if (!bi || this.selectedPump.PumpType !== PumpType.Horizontal) {
			this.availableSeals = [];
			return;
		}

		const pumpId = this.selectedPump.Id;
		const pd = await this.getPumpDataForBuild(pumpId);
		bi.forEach(x => x.Pump = pd);

		const allSeals = await PumpService.getShaftSeals(bi);
		const selected = this.selectedSeal;
		if (selected?.id) {
			const newSeal = allSeals.find(s => s.id === selected.id);
			if (newSeal && this.isDevloper)
				await store.dispatch(DebugActions.replace, { category: 'Seal', entries: newSeal?.Messages || [] });
		}
		this.availableSeals = allSeals;
	}

	public get bearingAssemblyFilter() {
		if (!this.values || !this.selectedPump || !this.selectedPump.Suitable || !this.selectedFrame)
			return null;
		const bi = {
			FrameId: this.selectedFrame.id,
			DriveArrangement: this.selectedDA
		} as BuildInputs;

		if (this.selectedDA === DriveArrangement.CustomDrive)
			bi.TransmissionLoad = this.values.getNumericValue('Drive.TransmissionLoad') || undefined;
		return this.dutyPoints.getBuildInputs(bi);
	}

	public async doSearchBearingAssemblies() {
		if (this.offline)
			return;

		const bi = this.bearingAssemblyFilter;
		if (!bi || !bi.length || !this.values.getNumericValue('Heads.PDH')) {
			this.availableBAs = [];
			return;
		}

		const pumpId = this.selectedPump.Id;
		const pd = await this.getPumpDataForBuild(pumpId);
		if (!pd)
			return;
		bi.forEach(x => x.Pump = pd);

		const allResults = await PumpService.getBearingAssemblies(bi);
		this.dutyPoints.baResults = allResults;
		const bas = this.dutyPoints.toBAresult;

		if (bas?.length && this.isDevloper) {
			const baMsgs = bas.reduce((a, b) => a.concat(b.Messages || []), [] as ValidationResult[]);
			store.dispatch(DebugActions.replace, { category: 'BearingAssy', entries: baMsgs });
		}
		this.availableBAs = bas;
	}

	public get selectedBA() {
		const ba = this.values?.get<BearingAssemblyResult>('BearingAssembly');
		return ba?.Id ? ba : null;
	}

	public selectBA(newBA: BearingAssemblyResult): boolean {
		// Make sure a container exists
		newBA = newBA || {} as BearingAssemblyResult;
		const oldBA = this.selectedBA || {} as BearingAssemblyResult;

		(newBA.Messages || []).forEach(m => m.External = true);
		const oldState = [oldBA.CritSpeedMin, oldBA.CritSpeedMax, oldBA.AxialLoad, oldBA.RadialLoad, oldBA.BearingArrangement,
			oldBA.BearingWetLife, oldBA.BearingDriveLife, oldBA.BearingThrustLife, oldBA.MaterialNumber, oldBA.Messages || null];
		const newState = [newBA.CritSpeedMin, newBA.CritSpeedMax, newBA.AxialLoad, newBA.RadialLoad, newBA.BearingArrangement,
			newBA.BearingWetLife, newBA.BearingDriveLife, newBA.BearingThrustLife, newBA.MaterialNumber, newBA.Messages || null];
		const shouldWrite = JSON.stringify(oldState) !== JSON.stringify(newState);

		if (shouldWrite)
			this.dutyPoints.writeSelectedBA(newBA);
		return shouldWrite;
	}

	public get selectedDA() {
		return this.values?.get<DriveArrangement>('Drive.DriveArrangement') || null;
	}

	public get driveOptionsFilter(): DriveParams {
		const frame = this.selectedFrame;
		const pump = this.selectedPump;
		const da = this.selectedDA;
		if (!this.values || !frame || !pump || !this.selectedBA)
			return;

		// This mimics the logic in RecalculateBuild server side. Would be nice to centralize.
		const dps = this.dutyPoints.asSizings.map(x => ({ RequiredPower: x.Data?.Pump?.AbsorbedPower || 0 } as DriveDutyPoint));
		if (this.useVFD) {
			const pumpSpeeds = this.dutyPoints.asSizings.map(x => x.Data.Pump.DutySpeed);
			let driveRatio: number;

			// Use actual speeds of selected drive
			if (this.selectedVBelt)
				driveRatio = this.selectedVBelt.MotorSheaveDiameter / this.selectedVBelt.PumpSheaveDiameter;
			else if (da === DriveArrangement.CustomDrive)
				driveRatio = this.values.getNumericValue('Drive.DriveRatio');

			if (driveRatio > 0)
				dps.forEach((dp, idx) => dp.TargetSpeed = Math.round(pumpSpeeds[idx] / driveRatio));
			else {
				// No drive selected - use relative speeds relative to fastest pump speed
				const maxSpeed = Math.max(...pumpSpeeds);
				if (maxSpeed > 0)
					dps.forEach((dp, idx) => dp.RelativeSpeed = pumpSpeeds[idx] / maxSpeed);
			}
		}

		const mainsFreq = this.useVFD ? undefined : this.values.getNumericValue('Site.MainsFreq');
		const mainsVoltage = this.values.getNumericValue('Site.MainsVoltage');
		return {
			DutyPoints: dps,
			PumpFrameId: frame.id,
			DriveArrangement: da || undefined,
			PumpType: pump.PumpType,
			MainsFreq: mainsFreq || undefined,
			Voltage: mainsVoltage || undefined
		};
	}

	public doSearchDriveOptions() {
		if (this.offline)
			return;

		if (!this.driveOptionsFilter) {
			this.setAvailableMotors([]);
			this.availableDAs = [];
			return;
		}

		return PumpService.getDriveOptions(this.driveOptionsFilter).then(res => {
			if (this.isDevloper)
				store.dispatch(DebugActions.replace, { category: 'Drive', entries: res.Messages });
			if (res?.UsableMotors)
				res.UsableMotors = res.UsableMotors.map(m => BuildManager.useMotorDutyPoint(m, this.dutyPoints.curDpIndex));
			this.setAvailableMotors(res?.UsableMotors);
			this.availableDAs = res?.UsableDriveArrangements || [];
		});
	}

	public get selectedMotor() {
		const val = this.values?.get<MotorResult>('Motor');
		return (val?.id) ? val : null;
	}

	public set selectedMotor(motor: MotorResult) {
		const batch = this.dutyPoints.asSizings.map((dp, idx) => {
			const dpMotor = BuildManager.useMotorDutyPoint(motor, idx);
			if (!BuildManager.samesame(dpMotor, dp?.Data?.Motor)) {
				// Don't save all duty points in each duty point...
				if (dpMotor)
					dpMotor.DutyPoints = undefined;
				const sections = dpMotor ? { Motor: dpMotor } : { Motor: {}, VBelt: {} };
				return { sizingId: dp.id, sections, target: 'Motor', newValue: dpMotor?.DisplayName };
			}
		});
		store.dispatch(SizingActions.updateSections, batch);
	}

	private static samesame(obj1: any, obj2: any): boolean {
		return JSON.stringify(obj1 || {}) === JSON.stringify(obj2 || {});
	}

	private static useMotorDutyPoint(motor: MotorResult, dpIndex: number) {
		return motor && Object.assign({}, motor, motor.DutyPoints[dpIndex]);
	}

	public get selectedVBelt() {
		const vb = this.values?.get<VBeltResult>('VBelt');
		return (vb?.Id) ? vb : null;
	}

	public set selectedVBelt(vb: VBeltResult) {
		const batch = this.dutyPoints.asSizings.map((dp, idx) => {
			const dpVbelt = BuildManager.useVBeltDutyPoint(vb, idx);
			if (!BuildManager.samesame(dpVbelt, dp.Data.VBelt)) {
				// Don't save all duty points in each duty point...
				if (dpVbelt)
					dpVbelt.DutyPoints = undefined;
				return { sizingId: dp.id, sections: { VBelt: dpVbelt || {} }, target: 'VBelt', newValue: dpVbelt?.Id };
			}
		});
		store.dispatch(SizingActions.updateSections, batch);
		if (!vb || !vb.Id)
			this.doSearchVBelts();
	}

	public get isVBeltArrangement() {
		return !!(this.selectedDA?.startsWith('VBelt'));
	}

	public get isCustomDrive() {
		return this.selectedDA === DriveArrangement.CustomDrive;
	}

	public get customDriveFilter() {
		if (this.selectedDA !== DriveArrangement.CustomDrive || !this.selectedPump?.Suitable || !this.selectedMotor?.id || !this.selectedBA?.Id)
			return null;
		return this.values.getNumericValue('Drive.DriveRatio') || null;
	}

	public get vBeltFilter() {
		if (!this.isVBeltArrangement || !this.selectedPump?.Suitable || !this.selectedMotor?.Drive || !this.selectedBA?.Id)
			return null;

		try {
			const dps = this.dutyPoints.asSizings.map(dp => ({
				MotorSpeed: dp.Data.Motor.CalculatedSpeed,
				MotorShaftPower: dp.Data.Motor.ShaftPower,
				PumpSpeed: dp.Data.Pump.DutySpeed,
				AxialLoad: dp.Data.BearingAssembly.AxialLoad,
				RadialLoad: dp.Data.BearingAssembly.RadialLoad,
				SlurryDensity: dp.Data.Slurry.SlurryDensity
			} as VBeltDutyPoint));

			return {
				MotorShaftDiameter: this.selectedMotor.ShaftDiameter,
				MotorShaftLength: this.selectedMotor.ShaftLength,
				Cfg: this.selectedMotor.Drive,
				UseVFD: this.useVFD,
				DriveArrangement: this.selectedDA,
				BearingAssemblyId: this.selectedBA.Id,
				BearingArrangement: this.selectedBA.BearingArrangement,
				DutyPoints: dps,
				VBeltDriveFactor: 1.0
			} as VBeltInputs;
		} catch (err) {
			// In some rare/racy cases, one of the duty points might lose its motor during recalc. If there is
			// a crash due to this, just give up on vbelt search silently.
			console.error('Something broke in vbelt inputs. Skipping search.');
			return null;
		}
	}

	public doSearchVBelts() {
		if (this.offline)
			return;

		const filter = this.vBeltFilter;
		if (!filter) {
			this.availableVBelts = [];
			this.vBeltMessages = [];
			return;
		}

		return this.getPumpDataForBuild(this.selectedPump.Id).then(pumpData => {
			filter.Pump = pumpData;
			return PumpService.getVBelts(filter).then(res => {
				if (this.isDevloper)
					store.dispatch(DebugActions.replace, { category: 'VBelt', entries: res.Messages });
				if (res?.Hits)
					res.Hits = res.Hits.map(m => BuildManager.useVBeltDutyPoint(m, this.dutyPoints.curDpIndex));
				this.availableVBelts = res.Hits;
				this.vBeltMessages = res.Messages || [];
			});
		});
	}

	private static useVBeltDutyPoint(belt: VBeltResult, dpIndex: number) {
		return belt && Object.assign({}, belt, belt.DutyPoints[dpIndex]);
	}

	public async recalc() {
		if (!this.selectedPump || !this.pumpManager.pumpDutyString || this.offline)
			return;

		let filterAtStart: string;
		let filterAtEnd: string;
		let res: RecalcResult;

		// We are doing this in a retry loop since a user might start to change stuff like crazy while recalculating
		do {
			await this.pumpManager.updateSelected();
			if (!this.selectedPump.Suitable)
				return;

			// If there is no preconfig, there is nothing to rebuild
			if (!this.selectedFrame && !this.selectedFlange)
				return;

			filterAtStart = this.pumpManager.pumpDutyString;
			res = await PumpService.recalculate(this.dutyPoints.asSizings);
			if (!res)
				return;

			filterAtEnd = this.pumpManager.pumpDutyString;
			if (filterAtStart && filterAtStart !== filterAtEnd)
				console.debug('Duty changed during pump recalc; retrying');
		} while (filterAtStart && filterAtStart !== filterAtEnd);

		store.dispatch(DebugActions.replace, { category: 'Rebuild', entries: res.Messages || [] });

		if (res.Changed?.length) {
			console.debug('Writing ' + res.Changed.length + ' updates after recalc');
			const batch = res.Changed.map(s => ({ sizing: s, target: 'recalculate' }));
			await store.dispatch(SizingActions.update, batch);
		}

		// Recalculate other stages if conditions changed in the base sizing
		const target = this.dutyPoints.asSizings.find(x => x.id === this.sizingId);
		if (target?.Staged && !target.ParentId) {
			const substages = store.get(SizingGetters.childSizings, target.id) as PumpDocument[];
			for (const stage of substages.filter(x => x.Data?.Pump?.Id))
				await BuildManager.recalc(stage);
		}
		return res.Messages as ValidationResult[];
	}

	public static async recalc(s: PumpDocument) {
		if (!s.Data?.Pump?.Id)
			return;
		console.debug('Starting pump reevaluation for ' + s.id);
		const dp = new DutyPoints(s);
		await dp.load();
		const pb = new ParamBag(dp.asSizings, s.id, true);
		const bm = new BuildManager();
		bm.load(pb, dp, false);
		await bm.recalc();
	}

	private setAvailableMotors(motors: MotorResult[]) {
		this.availableMotors = motors.sort((a, b) => a.PowerMargin - b.PowerMargin);
	}

	public getPumpDataForBuild(pumpId: string) {
		if (!this.pumpDataFetcher || this.loadedPumpDataId !== pumpId) {
			this.loadedPumpDataId = pumpId;
			this.pumpDataFetcher = PumpService.getPumpDataForBuild(pumpId);
		}
		return this.pumpDataFetcher;
	}

	private get isDevloper() {
		return !!(store.get(AuthGetters.hasRole, 'developer'));
	}

	public canApplyBuild(master: PumpDocument) {
		if (master?.Staged && !master.ParentId && this.selectedPump && this.selectedFrame) {
			const stages = new DutyPoints(master, true);
			const pumps = stages.asSizings.map(x => x?.Data?.Pump?.Id);
			if (pumps.length && pumps.every(x => x && x === pumps[0]))
				return true;
		}
		return false;
	}

	public async applyBuild(master: PumpDocument) {
		const stages = new DutyPoints(master, true);
		const msgs: string[] = [];
		const batch: any[] = [];
		let stageNum = 0;

		for (const stage of stages.asSizings) {
			++stageNum;
			if (stage.id === master.id || !stage.Data?.Pump?.Suitable)
				continue;

			// Apply first/master stage build sections to target stage
			const sections = {
				Frame: this.selectedFrame,
				Flange: this.selectedFlange,
				BearingAssembly: this.selectedBA,
				ShaftSeal: this.selectedSeal,
				Motor: this.selectedMotor,
				Drive: master.Data.Drive || null,
				VBelt: this.selectedVBelt
			};
			const pump = master.Data.Pump;
			await store.dispatch(SizingActions.setValue, { sizingId: stage.id, valueName: 'Pump.CaseMaterial', value: pump.CaseMaterial || null, skipSave: true });
			await store.dispatch(SizingActions.setValue, { sizingId: stage.id, valueName: 'Pump.ImpellerMaterial', value: pump.ImpellerMaterial || null, skipSave: true });
			await store.dispatch(SizingActions.updateSection, { sizingId: stage.id, sections, skipSave: true });

			// Update all duty values for the stage since right now they are only a copy of the first stage
			const res = await PumpService.recalculate([stage]);
			if (res?.Messages?.length)
				msgs.push(...res.Messages.filter(x => x.Severity === MessageSeverity.Info).map(x => `• Stage ${stageNum}: ${x.Message}`));

			// Save the recalculated version, or just the updated version in case the recalculation made no difference
			const newVersion = res.Changed?.length && res.Changed[0] || stage;
			batch.push({ sizing: newVersion, target: 'recalculate' });
		}

		if (batch.length)
			await store.dispatch(SizingActions.update, batch);

		if (msgs?.length)
			store.dispatch(SnackActions.set, msgs.join('\n'));
	}
}
