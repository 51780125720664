<template>
	<article class="border padding-x content-wrapper">
		<v-alert class="ma-3" :value="locked" transition="scale-transition" type="warning">
			<LockIcon showReason :model="model" />
		</v-alert>
		<v-tabs v-if="model" slider-size="5" v-model="currentTab" grow show-arrows class="sticky-tabs">
		<v-tabs-slider color="accent"></v-tabs-slider>
			<!-- slurry -->
			<v-tab class="tab" key="Slurry">
				<span class="d-flex">
					Slurry data
					<GroupValidation :values="values" :groups="['Slurry','Particles','Liquid']" />
				</span>
			</v-tab>
			<v-tab-item key="Slurry" :transition="false" :reverse-transition="false">
				<v-container class="content">
					<v-row>
						<p class="sizing-details">			
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<a v-on="on" :href="pdfUrl(7)" target="blank" tabindex="-1"><v-icon class="enquiry pl-1">$enquiry</v-icon></a>
							</template>
								<span>{{ urlToolTipText }}</span>
						</v-tooltip>
						</p>
						<InfoBox v-if="!locked && !offline && model && model.Staged && model.ParentId" class="infobox-wide">
							Pre-pump parameters can be edited from the first stage.
						</InfoBox>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12" md="6">
							<SizingSection class="mb-7" title="Particles" :values="values" :groups="['Particles']" expanded>
								<v-list-item >
									<v-list-item-content class="grid align-top pl-2 pr-2 mb-3">
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Particles.d100')" :target="target" class="details-minimize" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Particles.d80')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Particles.d50')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Particles.Sub200micron')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Particles.Sub40micron')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Particles.MillerNumber')" :target="target" />
									</v-list-item-content>
								</v-list-item>
								<a class="card-help-link pr-2 allcaps" :href="pdfUrl(21)" target="blank" tabindex="-1" >More about particle sizes...</a>
							</SizingSection>

							<SizingSection class="mb-7" title="PSD" :values="values" :expanded="showParticleCurve" @change="setShowParticleCurve">
								<v-list-item>
									<v-list-item-content class="grid align-top pl-2 pr-2">
										<ParticleCurve :values="values" :sizingId="model.id" class="wide" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>

							<SizingSection class="mb-7" title="Slurry" :values="values" :groups="['Slurry']" expanded>
								<SlurryData :locked="lockPrePumpConfig" :values="values" :target="target" />
							</SizingSection>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<SizingSection class="mb-7" title="Liquid" :values="values" :groups="['Liquid.Temp','Liquid.Density','Liquid.DynamicViscosity','Liquid.VapourPressure']" expanded>
								<v-list-item>
									<v-list-item-content class="grid align-top pl-2 pr-2">
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Liquid.Temp')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Liquid.Density')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Liquid.DynamicViscosity')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Liquid.VapourPressure')" :target="target" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>

							<SizingSection class="mb-7" title="CorrosionFactors" :values="values" :expanded="showCorrosion" :groups="corrosionProps.concat(['Liquid.pH'])">
								<v-list-item>
									<v-list-item-content class="grid align-top pl-2 pr-2 pb-2">
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Liquid.pH')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Liquid.ChlorideIons')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Liquid.PhosphateIons')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Liquid.SulfateIons')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Liquid.CorrosionIndex')" inline :target="target" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>

							<SizingSection class="mb-7" title="Derating" :values="values" :expanded="canEditManualDerating">
								<v-list-item>
									<v-list-item-content class="grid align-top pl-2 pr-2 pb-2">
										<SelectField :locked="lockPrePumpConfig" :param="getParam('Slurry.DeratingMethod')" :target="target" />
										<UnitNumeric :locked="!canEditManualDerating || lockPrePumpConfig" :param="getParam('Slurry.ManualHR')" readwrite :target="target" />
										<UnitNumeric :locked="!canEditManualDerating ||lockPrePumpConfig" :param="getParam('Slurry.ManualER')" readwrite :target="target" />
										<UnitNumeric :locked="!canEditManualDerating ||lockPrePumpConfig" :param="getParam('Slurry.ManualQR')" readwrite :target="target" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
					</v-row>
				</v-container>
			</v-tab-item>

			<!-- system details -->
			<v-tab class="tab" key="Heads">
				<span class="d-flex">
					System details
					<GroupValidation :values="values" :groups="['Heads','Inlet','Outlet']" />
				</span>
			</v-tab>
			<v-tab-item key="Heads" :transition="false" :reverse-transition="false">
				<v-container class="content">
					<v-row>
						<p class="sizing-details">
							<template v-if="model.MDP">Duty point:<b class="ml-1">{{model.Subtitle}}</b></template>
							<template v-else-if="model.Staged">Stage:<b class="ml-1">{{model.Subtitle}}</b></template>
							<ParamList :params="values" :names="['Slurry.SlurryDensity', 'Slurry.FlowRate']" inline columns mode="SingleDuty" />
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<a v-on="on" :href="pdfUrl(46)" target="blank" tabindex="-1"><v-icon class="enquiry pl-1">$enquiry</v-icon></a>
								</template>
									<span>{{ urlToolTipText }}</span>
							</v-tooltip>
						</p>
						<InfoBox v-if="!locked && !offline && model && model.Staged && model.ParentId" class="infobox-wide">
							Pre-pump parameters can be edited from the first stage.
						</InfoBox>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12" md="6">
							<SizingSection title="TDH" :values="values" :groups="calcTDH ? ['Inlet.TankPressureHead','Inlet.InletDesign','Inlet.PipeEndLoss',
								'Inlet.PipeLoss','Outlet.PipeLoss','Outlet.PipeEndLoss','Outlet.StaticOutlet'] : ['Heads']" expanded>
								<v-list-item>
									<v-list-item-content class="pl-2 pr-2 pt-0">
										<v-row>
											<v-col cols="12" class="pt-0" v-bind:class="{ 'col-sm-6': calcTDH, 'col-sm-12': !calcTDH }">
												<v-chip-group :value="calcTDH" column mandatory>
													<v-chip :value="false" :disabled="lockPrePumpConfig || model.Staged" :class="!calcTDH && 'sel-choice-chip'" :outlined="calcTDH"
														v-text="$t('TDHMode.Manual')" v-keyboard-click @click="calcTdhClick(false)" />
													<v-chip :value="true" :disabled="lockPrePumpConfig || model.Staged" :class="calcTDH && 'sel-choice-chip'" :outlined="!calcTDH"
														v-text="$t('TDHMode.Calculate')" v-keyboard-click @click="calcTdhClick(true)" />
												</v-chip-group>
												<template v-if="calcTDH && values.getNumericValue('Heads.TDH') > 0">
													<v-btn @click="showSysCurve = !showSysCurve" small class="sys-curve-btn mb-2">System curve</v-btn>
													<SystemCurve v-if="showSysCurve" :sizingId="model.id" :dutypoints="variants" @close="showSysCurve = false" />
												</template>
											</v-col>
											<v-col v-if="calcTDH" cols="12" sm="6" class="pt-0">
												<ParamList :params="values" :names="['Inlet.TankPressureHead','Inlet.InletDesign','Inlet.PipeEndLoss',
													'Inlet.PipeLoss','Outlet.PipeLoss','Outlet.PipeEndLoss','Outlet.StaticOutlet']" mode="SingleDuty" />
												<hr />
												<ParamList :params="values" :names="['Heads.TDH']" mode="SingleDuty" />
											</v-col>
										</v-row>
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-if="!calcTDH">
									<v-list-item-content class="pl-2 pr-2 pt-0">
										<v-row>
											<v-col cols="12" class="pb-0">
												<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Heads.InletHead')" :target="target" />
												<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Heads.NPSHA')" :target="target" />
												<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Heads.TDH')" :target="target" />
											</v-col>
										</v-row>
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<SizingSection title="Site" :values="values" :groups="['Site']">
								<v-list-item>
									<v-list-item-content class="grid pl-2 pr-2 pb-0">
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Site.Altitude')" readwrite :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Site.AmbientTemp')" readwrite :target="target" />
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-if="!lockPrePumpConfig && siteProjectDiff(['Altitude','AmbientTemp'])">
									<v-list-item-content class="pt-0 pr-1 pl-2 mb-3">
										<v-btn small @click="resetSite(['Altitude','AmbientTemp'])">▲ Reset to project defaults ▲</v-btn>
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12" md="6">
							<SizingSection v-if="calcTDH" title="Inlet" :values="values" :groups="['Inlet']">
								<v-list-item >
									<v-list-item-content class="grid pl-2 pr-2 pb-0">
										<SelectField :locked="lockPrePumpConfig" :param="getParam('Inlet.EndConfig')" :target="target" class="wide" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Inlet.InletMin')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Inlet.InletMax')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Inlet.InletDesign')" :target="target" readwrite />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Inlet.TankPressure')" :target="target" />
										<UnitNumeric v-if="calcTDH" inline :param="getParam('Heads.NPSHA')" readwrite  />
										<SimpleWarnings class="wide" :msgs="getParam('Inlet.PipeLoss').messages" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<SizingSection v-if="calcTDH" title="Outlet" :values="values" :groups="['Outlet']">
								<v-list-item>
									<v-list-item-content class="grid pl-2 pr-2">
										<SelectField :locked="lockPrePumpConfig" :param="getParam('Outlet.EndConfig')" :target="target" class="wide" />
										<UnitNumeric v-if="showCounterPressure" :locked="lockPrePumpConfig" :param="getParam('Outlet.CounterPressure')" :target="target" />
										<UnitNumeric v-if="showFlowAtPressure" :locked="lockPrePumpConfig" :param="getParam('Outlet.FlowAtPressure')" :target="target" />
										<UnitNumeric :locked="lockPrePumpConfig" :param="getParam('Outlet.StaticOutlet')" :target="target" class="wide details-minimize" readwrite />
										<SimpleWarnings class="wide" :msgs="getParam('Outlet.PipeLoss').messages" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12" md="6">
							<SizingSection v-if="calcTDH" title="InletPiping" :values="values" :groups="['Inlet.Pipes', 'Inlet.PipeLoss']">
								<PipeList :param="getParam('Inlet.Pipes')" target="Inlet.Pipes" :values="values" :locked="lockPrePumpConfig" location="inlet" />
							</SizingSection>
						</v-col>
						<v-col cols="12" sm="12" md="6">
							<SizingSection v-if="calcTDH" title="OutletPiping" :values="values" :groups="['Outlet.Pipes', 'Outlet.PipeLoss']">
								<PipeList :param="getParam('Outlet.Pipes')" target="Outlet.Pipes" :values="values" :locked="lockPrePumpConfig" location="outlet"/>
							</SizingSection>
						</v-col>
					</v-row>
				</v-container>
			</v-tab-item>

			<!-- pumps -->
			<v-tab class="tab" key="Pumps" :disabled="!canSearchPumps" :tabindex="canSearchPumps ? 0 : -1">
            <v-tooltip bottom max-width="500" open-delay="500" :disabled="canSearchPumps">
               <template v-slot:activator="{ on }">
                  <span v-on="on" class="d-flex">
                     Pump selection
                     <GroupValidation :values="values" :groups="['Pump']" :class="overflowScroll" />
                  </span>
               </template>
               <span>{{ pumpErrors || pumpMissingData }}</span>
            </v-tooltip>
         </v-tab>

			<v-tab-item key="Pumps" :transition="false" :reverse-transition="false">
				<v-container class="content">
					<v-row>
						<p class="sizing-details">
							<ParamList :params="values" :names="['Slurry.SlurryDensity', 'Slurry.FlowRate', 'Heads.PDH']" inline columns />
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<a v-on="on" :href="pdfUrl(56)" target="blank" tabindex="-1"><v-icon class="enquiry pl-1">$enquiry</v-icon></a>
								</template>
									<span>{{ urlToolTipText }}</span>
							</v-tooltip>
						</p>
					</v-row>
					<v-row>
						<v-col cols="12">
							<PumpList :buildManager="buildManager" :wq="wq" :messages="pumpMessages" :locked="locked || offline" :dutyPoints="dutyPoints"
								:target="target" :params="values" />
						</v-col>
					</v-row>
					<v-btn v-if="canStage" @click="showStagingDialog = true">{{ model.Staged ? 'Edit pump stages' : 'Use pump staging' }}</v-btn>
				</v-container>
			</v-tab-item>

			<!-- pump build -->
			<v-tab  class="tab" key="Build" :disabled="!canEditBuild" :tabindex="canEditBuild ? 0 : -1">
				<span class="d-flex">
					Pump build
					<GroupValidation :values="values" :groups="['Frame','BearingAssembly','Flange','ShaftSeal','Pump.CaseMaterial','Pump.ImpellerMaterial']" />
				</span>	
			</v-tab>
			<v-tab-item key="Build" :transition="false" :reverse-transition="false">
				<v-container v-if="selectedPump" class="content">
					<v-row>
						<p class="sizing-details">
							<ParamList :params="values" :names="['Pump.DisplayName', 'Pump.AbsorbedPower', 'Pump.DutySpeed', 'Drive.TypeCode']" inline columns />
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<a v-on="on" :href="pdfUrl(24)" target="blank" tabindex="-1"><v-icon class="enquiry pl-1">$enquiry</v-icon></a>
								</template>
									<span>{{ urlToolTipText }}</span>
							</v-tooltip>
						</p>
					</v-row>
					<v-row>
						<v-col cols="12" sm="12" md="6">
							<SizingSection title="Build" :values="values" :groups="['Frame','BearingAssembly','Flange']" expanded>
								<v-list-item>
									<v-list-item-content class="grid large pl-2 pr-2">
										<v-btn v-if="canApplyBuild" @click="applyBuild" class="mb-6">Apply build to other stages</v-btn>
										<InfoBox v-else-if="!locked && !offline && model && model.Staged">
											If the same pump model is selected in all stages, build settings can be copied from the first stage.
										</InfoBox>
										<SelectField :locked="locked || offline" :param="getParam('Frame')" :target="target" :items="availableFrames"
											item-key="id" item-text="Description" :onChange="onFrameChange">
											<template #suffix="{value}">
												<DeliveryReadiness :id="value.id" partType="Frame" :pumpId="selectedPump.Id" />
											</template>
										</SelectField>
										<SelectField  :locked="locked || offline" :param="getParam('Flange')" :target="target" :items="availableFlanges"
											item-key="id" item-text="Description" :onChange="onFlangeChange">
											<template #suffix="{ value }">
												<DeliveryReadiness v-if="!hasIntegratedFlanges" :id="value.id" partType="Flange" :pumpId="selectedPump.Id" />
											</template>
										</SelectField>
										<!-- For now, hide material selection in MDP children since there is no convenient way to enumerate materials -->
										<template v-if="model && (!model.MDP || !model.ParentId)">
											<!-- These two are readwrite to reflect changes from pump-select-uses-material-from-filter-settings (see PumpList) -->
											<SelectField :locked="locked || offline" :param="getParam('Pump.CaseMaterial')" :target="target" readwrite :items="caseMaterials">
												<template #suffix="{ value }">
													<DeliveryReadiness :id="selectedPump.Id" :caseMaterial="value.value" />
												</template>
											</SelectField>
											<SelectField :locked="locked || offline" :param="getParam('Pump.ImpellerMaterial')" :target="target" readwrite :items="impellerMaterials">
												<template #suffix="{ value }">
													<DeliveryReadiness :id="selectedPump.Id" :impellerMaterial="value.value" />
												</template>
											</SelectField>
										</template>
										<BearingAssemblyList v-if="selectedFrameId" :values="values" :dutyPoints="dutyPoints" :buildManager="buildManager" :locked="locked || offline" class="wide" :target="target" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
						<v-col cols="12" sm="12" md="6" v-if="selectedPump.PumpType === 'Horizontal' && !!selectedFrameId">
							<SizingSection title="ShaftSeal" :values="values" :groups="['ShaftSeal']" expanded>
								<v-list-item>
									<v-list-item-content class="grid pl-2 pr-2">
										<ShaftSealList :values="values" :buildManager="buildManager" :locked="locked || offline" class="wide" :target="target" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
					</v-row>
				</v-container>
			</v-tab-item>

			<!-- motor, drive and base -->
			<v-tab class="tab" key="Drive" :disabled="!canEditDrive" :tabindex="canEditDrive ? 0 : -1">
				<span class="d-flex">
					Motor, drive and base
					<GroupValidation :values="values" :groups="['Motor','Drive','VBelt']" />
				</span>
			</v-tab>
			<v-tab-item key="Drive" :transition="false" :reverse-transition="false">
				<v-container v-if="canEditDrive" class="content">
					<v-row>
						<p class="sizing-details">
							<ParamList :params="values" :names="['Pump.DisplayName', 'Pump.AbsorbedPower', 'Pump.DutySpeed', 'Drive.TypeCode']" inline columns />
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<a v-on="on" :href="pdfUrl(31)" target="blank" tabindex="-1"><v-icon class="enquiry">$enquiry</v-icon></a>
								</template>
									<span>{{ urlToolTipText }}</span>
							</v-tooltip>
						</p>
					</v-row>
					<v-row>
						<v-col>
							<SizingSection title="Motor" :values="values" :groups="['Motor']" expanded>
								<v-list-item>
									<v-list-item-content class="grid pl-2 pr-2 pb-0">
										<SelectField :locked="locked || offline" :param="getParam('Site.MainsFreq')" readwrite :target="target" />
										<UnitNumeric :locked="locked || offline" :param="getParam('Site.MainsVoltage')" readwrite :target="target" />
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-if="!lockPrePumpConfig && siteProjectDiff(['MainsFreq','MainsVoltage'])">
									<v-list-item-content class="pa-2 pt-0">
										<v-btn small @click="resetSite(['MainsFreq','MainsVoltage'])">▲ Reset to project defaults ▲</v-btn>
									</v-list-item-content>
								</v-list-item>
								<v-list-item dense>
									<v-list-item-content class="py-0 px-2">
										<v-checkbox :disabled="locked || offline || !canUseVFD" :label="$t('Drive.UseVFD')" v-model="useVFD" class="mt-0" hide-details />
									</v-list-item-content>
								</v-list-item>
								<MotorList :values="values" :buildManager="buildManager" :locked="locked || offline" :target="target" />
								<v-list-item>
									<v-list-item-content class="grid large pl-2 pr-2">
										<TextField :param="getParam('Drive.MotorNotes')" :locked="locked || offline" class="wide" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
						<v-col>
							<SizingSection title="Drive" :values="values" :groups="['Drive']" expanded>
								<v-list-item>
									<v-list-item-content class="grid large pl-2 pr-2">
										<SelectField :locked="locked || offline" :param="getParam('Drive.DriveArrangement')" :target="target" :items="availableDAs" :onChange="onDAChange" class="wide" />
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
									<v-list-item-content class="grid large pa-0 pl-2 pr-2">
										<TextField :locked="locked || offline" :param="getParam('Drive.DriveNotes')" class="wide" textArea="true" />
									</v-list-item-content>
								</v-list-item>
								<v-list-item v-if="customDrive">
									<v-list-item-content class="grid large">
										<UnitNumeric :locked="locked || offline" :param="getParam('Drive.DriveRatio')" :target="target" />
										<UnitNumeric :locked="locked || offline" :param="getParam('Drive.TransmissionLoad')" :target="target" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<SizingSection v-if="canEditVBelt" title="VBelt" :values="values" :groups="['VBelt']" :expanded="canEditVBelt">
								<v-list-item>
									<v-list-item-content class="grid pl-2 pr-2">
										<VBeltList :pump="selectedPump" :ba="selectedBA" :motor="selectedMotor" :values="values"
											:locked="locked || offline" class="wide" :buildManager="buildManager" :dutyPoints="dutyPoints" :target="target" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<SizingSection v-if="selectedBA && buildManager.selectedPump" title="Drawing" :values="values" :groups="[]">
								<v-list-item>
									<v-list-item-content class="large pa-5">
										<BADrawing :id="selectedBA.Id" :pumpData="buildManager.getPumpDataForBuild(buildManager.selectedPump.Id)"
											:vBelt="buildManager.selectedVBelt" :driveArr="buildManager.selectedDA" :motor="selectedMotor"
											flipVerticals class="drawing" />
									</v-list-item-content>
								</v-list-item>
							</SizingSection>
						</v-col>
					</v-row>
				</v-container>
			</v-tab-item>

			<!-- notes -->
			<v-tab class="tab" key="Notes">
				Notes
			</v-tab>
			<v-tab-item key="Notes" :transition="false" :reverse-transition="false">
				<v-container class="padding content content-wrapper" wrap>
					<v-row>
						<v-col cols="12">
							<v-card outlined class="base-padding">
								<v-card-title><h3>Notes</h3>	
								</v-card-title>
								<v-card-text>							
									<v-textarea :disabled="locked" outlined hide-details solo flat label="Write sizing notes..." :value="model.Notes"
									@blur="saveNote($event.target.value)" rows="12" maxlength="2000" />
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-tab-item>

			<StagingDialog v-if="showStagingDialog" :sizing="model" :tdh="getParam('Heads.TDH').getValue()"
				:maxpdh="getParam('ServiceClass.MaxHead').getValue()" @closed="showStagingDialog = false" />
		</v-tabs>
	<Confirm ref="confirm" />
	</article>
</template>

<style scoped lang="scss">
@import '@/sass/_variables.scss';

.v-tab {
	letter-spacing: 0 !important;
}

.v-tab:hover, .v-tab.tab:hover {
	background-color: $selection-lighten-1;
}

.v-tab.tab {
	background-color: $grey-lighten-1;
}

.border {
	border-top: 1px solid $grey-lighten-2;
}

.sizing-details {
	margin-top: 11px;
	margin-left: 12px;
	color: #4a4a4a;
	font-size: 14px;
	display: flex;
}

.overflowScroll {
	overflow: scroll;
}

p.sizing-details {
	border: none;
	width: 100%;
	margin-right: 12px;
	margin-bottom: 0;

	a {
		margin-right: 0;
		 margin-left: auto;
	}
}

/* Spacing between UnitNumerics*/
p.sizing-details div {
	margin-left: 10px;
}

@media only screen and (max-width: 800px) {
	article {
		max-width: 74%; // This sets the maximum width of the content (not headers, sidebars etc.), to make sure that it fits to small screens
	}
}

@media only screen and (max-width: 960px) {
	.content-wrapper {
		margin-top:80px;
	}
}

.card-help-link {
	margin-right: 16px;
	display: block;
	position: absolute;
	right: 0;
	bottom: 12px;
	font-size: 12px;	
}

.infobox-wide {
	margin: 12px;
	margin-bottom: 0;
	width: 100%;
}

.sys-curve-btn {
    position: absolute;
    bottom: 6px;
    max-width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;	
}

.v-list-item-menu {
	background-color: $selection-lighten-1;
}

.v-icon.enquiry {
	height: 17px;
}

.drawing {
	max-height: 600px; 
	max-width: 600px;
}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import InsightService from '@/services/insight.service';
	import SelectField from '@/components/Fields/SelectField.vue';
	import UnitNumeric from '@/components/Fields/UnitNumeric.vue';
	import TextField from '@/components/Fields/TextField.vue';
	import ParamList from '@/components/ParamList.vue';
	import PumpList from '@/components/PumpList.vue';
	import PipeList from '@/components/PipeList.vue';
	import GroupValidation from '@/components/GroupValidation.vue';
	import SizingSection from '@/components/SizingSection.vue';
	import BearingAssemblyList from '@/components/BearingAssemblyList.vue';
	import VBeltList from '@/components/VBeltList.vue';
	import LockIcon from '@/components/LockIcon.vue';
	import MotorList from '@/components/MotorList.vue';
	import ShaftSealList from '@/components/ShaftSealList.vue';
	import SlurryData from '@/components/SlurryData.vue';
	import SimpleWarnings from '@/components/SimpleWarnings.vue';
	import InfoBox from '@/components/InfoBox.vue';
	import Confirm from '@/components/Confirm.vue';
	import SystemCurve from '@/components/SystemCurve.vue';
	import ParticleCurve from '@/components/ParticleCurve.vue';
	import DeliveryReadiness from '@/components/DeliveryReadiness.vue';
	import BADrawing from '@/components/BADrawing.vue';
	import { PumpDocument, MessageSeverity, TDHMode, ValidationResult, OutletEndConfig, DocState, DeratingMethod, StagingErrorSource, PumpProject, SiteInfo } from 'types/dto/CalcServiceDomain';
	import { ParamBag } from '@/common/ParamBag';
	import { ParamValue } from '@/common/ParamValue';
	import { PumpResult } from 'types/dto/PumpSearch';
	import { BasePumpFrameDef, UsablePumpFrame, FlangeDef, MotorResult, DriveArrangement } from 'types/dto/PumpBuild';
	import store, { SnackActions, AuthGetters, SizingActions, NetworkGetters, ProjectGetters } from '@/store';
	import StagingDialog from '@/views/dialogs/StagingDialog.vue';
	import { DutyPoints } from '@/common/DutyPoints';
	import BuildManager from '@/common/BuildManager';
	import SizingInfo from '@/common/SizingInfo';
	import WorkQueue from '@/common/WorkQueue';

	const openedTab: any = {};
	let showParticleCurve = false;

	@Component({
		name: 'sizing',
		components: {
			SelectField,
			UnitNumeric,
			TextField,
			ParamList,
			PumpList,
			PipeList,
			GroupValidation,
			SizingSection,
			BearingAssemblyList,
			VBeltList,
			StagingDialog,
			LockIcon,
			MotorList,
			ShaftSealList,
			SlurryData,
			SimpleWarnings,
			InfoBox,
			Confirm,
			SystemCurve,
			ParticleCurve,
			DeliveryReadiness,
			BADrawing
		}
	})
	export default class Sizing extends Vue {
		@Prop() public model: PumpDocument;
		@Prop() public dutyPoints: DutyPoints;
		@Prop() public values: ParamBag;

		public currentTab: number = null;
		public showStagingDialog: boolean = false;
		public showSysCurve: boolean = false;
		public readonly buildManager: BuildManager = new BuildManager();
		public wq: WorkQueue;

		// Properties that should be written to all duty points blindly (i.e. duty point independent)
		private static readonly MultiWriteProps = [
			'Pump.CaseMaterial', 'Pump.ImpellerMaterial', 'Pump.TrimPercentage',
			'Site.Altitude', 'Site.AmbientTemp', 'Site.MainsFreq', 'Site.MainsVoltage',
			'Drive.DriveNotes', 'Drive.MotorNotes', 'Drive.DriveRatio', 'Drive.TransmissionLoad'
		];

		private static readonly CorrosionProps = ['Liquid.ChlorideIons', 'Liquid.PhosphateIons', 'Liquid.SulfateIons', 'Liquid.CorrosionIndex'];
		public readonly corrosionProps = Sizing.CorrosionProps;
		public readonly urlToolTipText = 'More information...';

		public created() {
			this.wq = new WorkQueue();
			this.buildManager.load(this.values, this.dutyPoints, !this.locked);
		}

		public mounted() {
			// Switch to appropriate tab
			const m = this.model;
			const tabId = m && (m.ParentId || m.id);
			let preferredTab = tabId && openedTab[tabId] as any as number;

			// Prevent disabled tabs
			if (preferredTab === 2 && !this.canSearchPumps || preferredTab === 3 && !this.canEditBuild || preferredTab === 4 && !this.canEditDrive)
				preferredTab = null;

			// Go to pump selection if staged or mdp with pump selected
			if (preferredTab == null && this.canSearchPumps && m?.Staged)
				preferredTab = 2;

			if (preferredTab != null && this.currentTab !== preferredTab)
				this.currentTab = preferredTab;
		}

		public pdfUrl(ref: string | number) {
			const base = 'https://www.mogroup.com/globalassets/industry-pages/mining-industry-pages/slurry-handling/downloads/slurry-pumps-and-seals/handbook/slurry-pump-handbook_full_mo_web.pdf';
			return ref ? base + '#page=' + ref : base;
		}

		public get offline() {
			return !store.get(NetworkGetters.connected);
		}

		get overflowScroll() {
			if (screen.width <= 840)
				return 'overflowScroll';
			return '';
		}

		public get PDH() { return this.values.getNumericValue('Heads.PDH'); }
		public get flowRate() { return this.values.getNumericValue('Slurry.FlowRate'); }

		public get target() {
			// Don't allow commenting on deleted sizings
			const m = this.model;
			if (m?.DocState === DocState.Deleted)
				return null;

			// Direct all comments to "parent sizing"
			return m && (m.ParentId || m.id) || null;
		}

		public get variants() { return this.dutyPoints?.asSizings; }

		public get pumpMessages(): ValidationResult[] {
			return this.values?.errorsInGroup('Pump')?.Messages || [];
		}

		public get pumpMissingData() {
			if (!this.PDH || !this.flowRate)
				return 'TDH and flow rate must be specified to search for pumps';
			return this.pumpMessages.filter(x => x.Severity === MessageSeverity.Missing).map(x => x.Message).join('\n');
		}

		public get pumpErrors() {
			return this.pumpMessages.filter(x => x.Severity >= MessageSeverity.Error).map(x => x.Message).join('\n');
		}

		public saveNote(data: string) {
			if (this.model?.id)
				store.dispatch(SizingActions.setNote, { sizingId: this.model.id, text: data });
		}

		public get showCounterPressure() {
			if (!this.calcTDH)
				return false;
			const mode = this.values.get<OutletEndConfig>('Outlet.EndConfig');
			return [OutletEndConfig.Cyclone, OutletEndConfig.Nozzle, OutletEndConfig.PressureVessel, OutletEndConfig.PressFilter].includes(mode);
		}

		public get showFlowAtPressure() {
			// Show specified flow for flow-dependent end configs
			if (!this.showCounterPressure)
				return false;
			const mode = this.values.get<OutletEndConfig>('Outlet.EndConfig');
			return [OutletEndConfig.Nozzle].includes(mode);
		}

		public get showCorrosion() {
			const pH = this.values.getNumericValue('Liquid.pH');
			return pH > 0 && pH !== 7 || this.corrosionProps.some(x => this.values.getNumericValue(x) > 0);
		}

		public get canSearchPumps() {
			// Disallow search if errors are present, except staging errors that can only be fixed in the search tab
			const hasFatalErrors = this.pumpMessages.some(x => x.Severity >= MessageSeverity.Error && x.Source !== StagingErrorSource);
			return !!(this.selectedPump?.Id || !hasFatalErrors && !this.pumpMissingData?.length);
		}

		public get canEditBuild() {
			const p = this.selectedPump;
			if (!p?.Id)
				return false;
			if (p.Suitable)
				return true;

			// Hacky: allow editing build in the presence of material errors since that is where you
			// would need to go to change the faulty material... (only editable in the first DP in MDP mode!)
			const isDutyPointWithoutMaterial = this.dutyPoints?.isMDP && this.model?.ParentId;
			if (!isDutyPointWithoutMaterial && p.Messages?.length && p.Messages.every(x => x.Severity < MessageSeverity.Error || x.ParamName?.includes('Material')))
				return true;
			return false;
		}

		public get canEditDrive() {
			return !!(this.canEditBuild && this.selectedFrameId && this.selectedBA);
		}

		public get canEditVBelt() {
			if (!this.canEditDrive || !this.selectedMotor)
				return false;
			return this.buildManager.isVBeltArrangement;
		}

		public get customDrive() {
			return this.canEditDrive && this.buildManager.isCustomDrive;
		}

		public get canApplyBuild() {
			if (this.locked || this.offline)
				return false;
			return this.buildManager.canApplyBuild(this.model);
		}

		public async applyBuild() {
			return await this.buildManager.applyBuild(this.model);
		}

		public get canEditManualDerating() {
			return this.values.get<DeratingMethod>('Slurry.DeratingMethod') === DeratingMethod.Manual;
		}

		public get lockPrePumpConfig() {
			return !!(this.locked || this.model.Staged && this.model.ParentId);
		}

		public get locked(): boolean {
			return SizingInfo.isLocked(this.model);
		}

		public get isDevloper() {
			return !!(store.get(AuthGetters.hasRole, 'developer'));
		}

		public get selectedPump() {
			const pump = this.values.get<PumpResult>('Pump');
			return (pump?.Id) ? pump : null;
		}

		public get availableDAs() {
			return this.buildManager.availableDAs;
		}

		public get selectedFrameId() {
			const val = this.values.get<BasePumpFrameDef>('Frame');
			return (val?.id) || null;
		}

		public get selectedMotor() {
			const val = this.values.get<MotorResult>('Motor');
			return val?.id ? val : null;
		}

		public get selectedBA() {
			return this.buildManager.selectedBA;
		}

		public get availableFrames(): BasePumpFrameDef[] {
			return this.buildManager.availableFrames;
		}

		public get availableFlanges() {
			return this.buildManager.availableFlanges;
		}

		public get caseMaterials() {
			const pump = this.selectedPump;
			const mats = pump?.CaseMaterials || [];
			if (pump.CaseMaterial && !mats.includes(pump.CaseMaterial))
				return [...mats, pump.CaseMaterial ];
			return mats;
		}

		public get impellerMaterials() {
			const pump = this.selectedPump;
			const mats = pump?.ImpellerMaterials || [];
			if (pump.ImpellerMaterial && !mats.includes(pump.ImpellerMaterial))
				return [...mats, pump.ImpellerMaterial ];
			return mats;
		}

		public get showParticleCurve() {
			return showParticleCurve;
		}

		public setShowParticleCurve(x: any) {
			showParticleCurve = Boolean(x);
		}

		public onFrameChange(frame: UsablePumpFrame) {
			this.dutyPoints.updateSections({ Frame: frame || {} }, 'Frame', frame?.Description);
		}

		public get canUseVFD() {
			const da = this.values.get<string>('Drive.DriveArrangement');
			return da && da !== DriveArrangement.None;
		}

		public get useVFD() {
			return !!this.getParam('Drive.UseVFD').getValue();
		}

		public set useVFD(value: boolean) {
			if (value)
				InsightService.trackEvent('Sizing:EnableVFD');
			this.dutyPoints.setAll('Drive.UseVFD', !!value);
		}

		public onFlangeChange(flange: FlangeDef) {
			this.dutyPoints.updateSections({ Flange: flange || {} }, 'Flange', flange?.Description);
		}

		public get hasIntegratedFlanges() {
			const integratedSeries = ['VT', 'VF', 'VS', 'VASA HD', 'STHM', 'STGVA'];
			const seriesParts = this.selectedPump?.Id?.split(/[0-9]/);
			const series = seriesParts?.length ? seriesParts[0] : null;
			if (series?.length && integratedSeries.includes(series))
				return true;
			return false;
		}

		public onDAChange(da: DriveArrangement) {
			this.dutyPoints.setAll('Drive.DriveArrangement', da);
			if (!da || da === DriveArrangement.None)
				this.dutyPoints.updateSections({ VBelt: {} });
		}

		// Reactive getters/watcher pairs that trigger on param change and refresh shared build data

		private get pumpFilter() {
			if (this.offline || this.locked)
				return null;
			return JSON.stringify(this.buildManager.pumpDutyString);
		}

		private get selectedPumpConfig() {
			const p = this.selectedPump;
			return `${p?.ImpellerMaterial || ''}_${p?.CaseMaterial || ''}_${p?.TrimPercentage || ''}`;
		}

		@Watch('selectedPumpConfig')
		private selectedPumpConfigChanged(filter: string, oldFilter: string) {
			if (filter && oldFilter)
				this.wq.replace('UpdatePumpMaterial', () => this.buildManager.updatePump());
		}

		@Watch('pumpFilter')
		private pumpFilterChanged(filter: string, oldFilter: string) {
			if (filter && oldFilter && this.selectedPump)
			 	this.enqueueRecalc();
		}

		private get buildOptionsTrigger() {
			if (!this.offline && !this.locked && this.buildManager.buildOptionsFilter)
				return JSON.stringify(this.buildManager.buildOptionsFilter) + this.selectedPump?.Id;
		}

		@Watch('buildOptionsTrigger')
		private buildOptionsTriggerChanged(filter: string, oldFilter: string) {
			if (filter)
				this.wq.replace('SearchBuildOpts', () => this.buildManager.searchBuildOptions());
		}

		private get bearingAssemblyFilter() {
			if (!this.offline && !this.locked && this.buildManager.bearingAssemblyFilter)
				return JSON.stringify(this.buildManager.bearingAssemblyFilter);
		}

		@Watch('bearingAssemblyFilter')
		private baFilterChanged(filter: string, oldFilter: string) {
			if (filter && oldFilter && (this.selectedBA || this.values.get('ShaftSeal.Description')))
				this.enqueueRecalc();

			if (filter) {
				this.wq.replace('SearchBAs', () => {
					return Promise.all([
						this.buildManager.doSearchBearingAssemblies(),
						this.buildManager.doSearchSeals()
					]);
				});
			}
		}

		private get driveOptionsFilter() {
			if (!this.offline && !this.locked && this.buildManager.driveOptionsFilter)
				return JSON.stringify(this.buildManager.driveOptionsFilter);
		}

		@Watch('driveOptionsFilter')
		private driveOptionsChanged(filter: string, oldFilter: string) {
			if (filter && oldFilter && (this.selectedMotor?.id || this.values.get('Drive.DriveArrangement')))
				this.enqueueRecalc();
			if (filter)
				this.wq.replace('SearchDriveOpts', () => this.buildManager.doSearchDriveOptions());
		}

		private get vBeltFilter() {
			if (this.offline || this.locked)
				return null;
			if (this.buildManager.isCustomDrive)
				return JSON.stringify(this.buildManager.customDriveFilter);
			if (this.buildManager.vBeltFilter)
				return JSON.stringify(this.buildManager.vBeltFilter);
			return null;
		}

		@Watch('vBeltFilter')
		private vbeltFilterChanged(filter: string, oldFilter: string) {
			if (filter && oldFilter)
				this.enqueueRecalc();

			// If no vbelt is selected, update list. This causes no alternatives to show when one is selected.
			if (filter && !this.buildManager.selectedVBelt && !this.customDrive)
				this.wq.replace('SearchVBelts', () => this.buildManager.doSearchVBelts());
		}

		private static isMessageFromRecalc(x: ValidationResult) {
			return x.Severity === MessageSeverity.Info && x.Source === 'Recalculate';
		}

		private async enqueueRecalc() {
			this.wq.replace('RecalcBuild', async () => {
				const res = await this.buildManager.recalc();
				if (res?.length) {
					const report = res.filter(Sizing.isMessageFromRecalc).map(x => '• ' + x.Message).join('\n');
					if (report?.length)
						setTimeout(() => store.dispatch(SnackActions.set, report), 3000);
				}
			});
		}

		public get canStage(): boolean {
			return !this.locked && !this.model?.MDP && !this.model.ParentId && !this.offline && this.values.getNumericValue('Heads.TDH') > 0;
		}

		@Watch('currentTab')
		public onTabChange(idx: number) {
			const tabId = this.model && (this.model.ParentId || this.model.id);
			openedTab[tabId] = idx;

			if (!this.canStage || idx !== 2) {
				this.showStagingDialog = false;
				return;
			}

			// Check for reasons to throw up the staging dialog
			if (this.model?.Staged) {
				if (this.needsRestage)
					this.showStagingDialog = true;
			} else {
				// Not staged; show dialog if max head for service class is exceeded
				const pdh = this.values.getNumericValue('Heads.PDH');
				const maxPDH = this.values.getNumericValue('ServiceClass.MaxHead');
				this.showStagingDialog = pdh > maxPDH;
			}
		}

		public get needsRestage() {
			if (this.currentTab !== 2 || !this.model?.Staged || !this.canStage)
				return false;

			// Staged; show restage dialog if backend reports that stages need to be distributed
			return this.pumpMessages?.some(x => x.Source === StagingErrorSource && x.Severity === MessageSeverity.Error) === true;
		}

		@Watch('needsRestage')
		public onNeedsRestageChanged(show: boolean) {
			if (show)
				this.showStagingDialog = true;
		}

		public getParam(n: string): ParamValue {
			if (this.dutyPoints?.isMDP && Sizing.MultiWriteProps.includes(n))
				return ParamBag.getMultiWriteParam(n, this.variants.map(x => x.id));
			return this.values.getParam(n);
		}

		public get calcTDH() {
			return this.values.get<TDHMode>('Heads.TDHMode') === TDHMode.Calculate;
		}

		public calcTdhClick(calculate: boolean) {
			const oldValue = this.calcTDH;
			const newValue = calculate ? TDHMode.Calculate : TDHMode.Manual;
			const doIt = () => this.getParam('Heads.TDHMode').setValue(newValue);

			if (!oldValue || newValue === TDHMode.Calculate)
				doIt();
			else {
				const dialog: any = this.$refs.confirm;
				dialog.open('This will clear piping, inlet and outlet configuration').then((yes: any) => yes && doIt());
			}
		}

		public siteProjectDiff(parts: Array<keyof SiteInfo>) {
			const proj = this.model && store.get(ProjectGetters.project, this.model.ProjectId) as PumpProject;
			// PQP projects do not use default project settings, so skip reset button
			if (proj?.QuoteId)
				return false;
			return proj?.Site && parts.some(x =>
				proj.Site[x] != null && proj.Site[x] !== this.values.get<number>(`Site.${x}`)) || false;
		}

		public resetSite(parts: Array<keyof SiteInfo>) {
			const proj = store.get(ProjectGetters.project, this.model.ProjectId) as PumpProject;
			if (proj?.Site)
				parts.forEach(x => this.values.set(`Site.${x}`, proj.Site[x]));
		}
	}
</script>