<template>
	<v-card flat class="pl-2 pr-2 pb-0">
		<v-card-text class="py-0">
			<FilterList :filters="filters" v-if="showFilters" xlatePrefix='Motor' class="mb-1" />
			<v-checkbox  dense v-model="includeNonStd" label="Allow non-standard mounts" v-if="hasNonStd" hide-details class=" inline-block mr-3 mt-0" />
			<v-checkbox dense v-model="includeOversized" label="Allow oversized motors" v-if="hasOversized" hide-details class="inline-block mr-3 mt-0" />
			<v-checkbox dense v-model="includeCustomVoltage" label="Allow custom voltage" v-if="hasCustomVoltages" hide-details class="inline-block mt-0" />
			
			<div v-if="showFilters" class="mb-3 mt-3" >
				<v-slider :label="$t('Motor.MinPowerMargin')" :thumb-label="false" v-model="safetyMargin" min="0" max="100" step="5" hide-details>
					<!-- Workaround p with style to prevent line breaking of the percentage despite &nbsp; -->
					<template v-slot:append><p class="safety-margin">{{safetyMargin}}&nbsp;%</p></template>
				</v-slider>
			</div>
			<div v-if="noMotors">
				<i>No standard motors are available for this configuration.</i>
			</div>
			<v-list max-height="300px" class="overflow-y-auto pa-0">
				<v-list-item-group>
					<v-list-item v-for="(item, index) in availableMotors" :key="item.id" class="v-list-border" @click="toggle(index)"
						:class="selectedKey === key(item) ? 'sel-list-item' : 'active-item'"
						active-class="active-item" @mouseover="showCommentIcon = selectedKey === key(item)" @mouseout="showCommentIcon = false">
						<v-list-item-icon>
							<v-icon :color="selectedKey === key(item) ? 'selection' : 'grey'" :disabled="locked" @click.stop="itemClick(item)">
								{{ selectedKey === key(item) ? 'radio_button_on' : 'radio_button_off' }}
							</v-icon>
						</v-list-item-icon>
						<v-list-item-content v-for="({vb}, idx) of [{ vb: getValueBag(item) }]" :key="idx">
							<v-list-item-title>
								<v-tooltip bottom :disabled="!warnings(item)" color="warning">
									<template v-slot:activator="{ on }">
										<h3 class="d-flex align-center" v-on="on">
											{{ item.DisplayName }}
											<v-icon class="cursor-help ml-1" v-if="warnings(item)" small color="warning">error</v-icon>
											<DeliveryReadiness v-if="!!pumpId" partType="Motor" :id="item.id" :pumpId="pumpId" :showAll="true" title="Motor" />
											<DeliveryReadiness v-if="item.MotorFrame && driveArrDRcontext" partType="DriveArrangement" :id="item.MotorFrame"
												:pumpId="pumpId" :context="driveArrDRcontext" :showAll="isInternal" title="Drive arrangement" />
										</h3>
									</template>
									<div v-for="w of warnings(item)" v-text="w.Message" :key="w.Message" />
								</v-tooltip>
								<CommentIcon v-if="target && selectedKey === key(item)" :target="target" field="Motor" type="Comment" :visible="showCommentIcon" />
							</v-list-item-title>
							<v-list-item-subtitle class="grid auto" v-if="!expandContent[index]">
								<ParamList :params="vb" :names="propsBrief" :inline="true" />
							</v-list-item-subtitle>
							<v-list-item-content class="grid auto" v-else>
								<ParamList :params="vb" :names="props1" />
								<ParamList :params="vb" :names="props2" />
								<div v-if="item.Drive && item.Drive.CrsMin">
									<ParamList v-if="expandDimensions[index]" :params="vb" :names="props3" />
									<a v-else x-small link href="#" @click.stop.prevent="toggleDimensions(index)" class="mt-1">Mounting kit details...</a>
								</div>
							</v-list-item-content>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
			<div class="pt-2" v-if="(buildManager.availableMotors || []).length">
				<i>Showing {{ availableMotors && availableMotors.length }} motors of {{ (buildManager.availableMotors || []).length }}</i>
			</div>
		</v-card-text>
	</v-card>
</template>

<style lang="scss" scoped>
p.safety-margin {
	min-width: 50px;
}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop } from 'vue-property-decorator';
	import { ParamBag } from '@/common/ParamBag';
	import ParamList from '@/components/ParamList.vue';
	import BuildManager from '@/common/BuildManager';
	import { BasePumpFrameDef, DriveArrangement, MotorResult } from 'types/dto/PumpBuild';
	import ItemFilter from '@/common/ItemFilter';
	import FilterList from '@/components/FilterList.vue';
	import CommentIcon from '@/components/CommentIcon.vue';
	import DeliveryReadiness from '@/components/DeliveryReadiness.vue';
	import InsightService from '@/services/insight.service';
	import { MessageSeverity } from 'types/dto/CalcServiceDomain';
	import store, { AuthGetters } from '@/store';

	@Component({
		components: {
			ParamList,
			FilterList,
			CommentIcon,
			DeliveryReadiness,
		}
	})
	export default class MotorList extends Vue {
		@Prop() public values: ParamBag;
		@Prop() public locked: boolean;
		@Prop() public buildManager: BuildManager;
		@Prop() public target: string;

		public readonly propsBrief = [ 'Motor.FullLoadSpeed', 'Motor.PowerMargin' ];
		public readonly props1 = [ 'Motor.RatedPower', 'Motor.FullLoadSpeed', 'Motor.ShaftDiameter', 'Motor.MotorFrame' ];
		public readonly props2 = [ 'Motor.ShaftPower', 'Motor.CalculatedSpeed', 'Motor.RunningFreq', 'Motor.PowerMargin' ];
		public readonly props3 = [ 'Motor.MountingKit', 'Motor.Drive.CenterDistance', 'Motor.Drive.MaxSheaveWidth', 'Motor.Drive.MaxSheaveMotor', 'Motor.Drive.MaxSheavePump' ];

		public expandContent: boolean[] = [];
		public expandDimensions: boolean[] = [];
		public safetyMargin: number = 15;
		public showCommentIcon: boolean = false;
		public includeNonStd: boolean = false;
		public includeOversized: boolean = false;
		public includeCustomVoltage: boolean = false;

		public readonly filters = [
			new ItemFilter<MotorResult>('MotorStandard'),
			new ItemFilter<MotorResult>('Poles')
		];

		public created() {
			// Set default filters
			const freq = this.values.getNumericValue('Site.MainsFreq');
			if (freq && freq > 57 || !freq && ParamBag.useImperial(this.values?.sizingId))
				this.filters[0].defaultValue = 'NEMA';
			else
				this.filters[0].defaultValue = 'IEC';

			this.filters[1].defaultValue = '4';
		}

		public mounted() {
			this.buildManager.doSearchDriveOptions();
		}

		public get hasNonStd() {
			return !!(this.buildManager.availableMotors?.some(x => x.NonStdMount));
		}

		public get hasOversized() {
			return !!(this.buildManager.availableMotors?.some(x => this.isOversized(x)));
		}

		public get hasCustomVoltages() {
			return !!(this.buildManager.availableMotors?.some(x => x.CustomVoltage));
		}

		public get noMotors() {
			return this.buildManager.availableMotors && !this.buildManager.availableMotors.length;
		}

		public get isInternal() {
			return store.get(AuthGetters.internalUser);
		}

		public get availableMotors() {
			const available = this.buildManager.availableMotors;

			const pm = this.safetyMargin;
			let filtered = available.filter(x => x.DutyPoints ? !x.DutyPoints.some(dp => dp.PowerMargin < pm) : x.PowerMargin >= pm);
			this.filters.forEach(f => filtered = f.filter(filtered));
			this.filters.forEach(f => f.update(filtered));

			if (!this.includeNonStd)
				filtered = filtered.filter(x => !x.NonStdMount);
			if (!this.includeOversized)
				filtered = filtered.filter(x => !this.isOversized(x));
			if (!this.includeCustomVoltage)
				filtered = filtered.filter(x => !x.CustomVoltage);

			// Put selected item on top if filtered away
			if (this.selectedKey && !filtered.some(x => x.id === this.selectedKey))
				filtered = [this.selectedItem].concat(filtered);
			return filtered;
		}

		public key(m: MotorResult) {
			return m?.id || null;
		}

		public get pumpId() {
			return this.buildManager?.selectedPump?.Id || null;
		}

		public get driveArrDRcontext() {
			const ctx = {
				driveArr: this.buildManager.driveOptionsFilter.DriveArrangement,
				frameSize: this.values.get<BasePumpFrameDef>('Frame')?.id
			}
			return ctx.driveArr && ctx.driveArr !== DriveArrangement.None && ctx.frameSize ? ctx : null;
		}

		public getValueBag(m: MotorResult) {
			const curSel = this.selectedItem;
			if (curSel && this.key(curSel) === this.key(m))
				return this.values;

			const mixed = (m.DutyPoints || []).map((x, idx) => ({ values: { Motor: Object.assign({}, m, x) }, messages: null }));
			return this.values.createVariants(mixed);
		}

		public isOversized(m: MotorResult) {
			return m?.RatedPower >= 5500 && (m.PowerMargin > 100 && !m.DutyPoints || m.DutyPoints?.every(x => x.PowerMargin > 100)) || false;
		}

		public warnings(m: MotorResult) {
			const warns = m?.Messages;
			return warns?.length ? warns.filter(x => x.Severity >= MessageSeverity.Info) : null;
		}

		public get selectedItem() {
			const m = this.buildManager.selectedMotor;
			// Try to show a motor returned from search service with all duty points in it
			const freshMotor = m?.id && this.buildManager.availableMotors.find(x => x.id === m.id);
			return freshMotor || m || null;
		}

		public get selectedKey() {
			return this.key(this.selectedItem);
		}

		public get showFilters() {
			return (this.buildManager.availableMotors || []).length >= 1;
		}

		public toggle(index: number) {
			Vue.set(this.expandContent, index, !this.expandContent[index]);
		}

		public toggleDimensions(index: number) {
			Vue.set(this.expandDimensions, index, !this.expandDimensions[index]);
		}

		public itemClick(m: MotorResult) {
			if (!this.locked) {
				const oldMotor = this.buildManager.selectedMotor;
				if (oldMotor?.id && m?.id && oldMotor.id === m.id)
					m = null;
				this.buildManager.selectedMotor = m;
				if (m)
					InsightService.trackEvent('Sizing:SelectMotor', m);
				else
					InsightService.trackEvent('Sizing:DeselectMotor', oldMotor);
			}
		}
	}
</script>
