import { ValidationResult } from './CalcServiceDomain';
import { PumpType } from './PumpSearch';

export interface BuildParams {
	UsableFrames: string[];
	UsableFlanges: string[];
}

export interface ShaftSealDuty {
	Suitable: boolean;
	Note: string;
	QuenchPressure: number;
	QuenchFlow: number;
	MaxInletHead: number;
}

export interface ShaftSealResult extends ShaftSealDuty {
	// Static data
	id: string;
	Type: string;
	Description: string;

	// Calculated
	Messages: ValidationResult[];

	// Per duty point values
	DutyPoints: ShaftSealDuty[];
}

export interface FlangeDef {
	id: string;
	Standard: string;
	Description: string;
}

export interface BasePumpFrameDef {
	id: string;
	Description: string;
}

export type UsablePumpFrame = BasePumpFrameDef;

export interface PumpBuildOptions {
	UsableFrames: UsablePumpFrame[];
	UsableFlanges: FlangeDef[];
}

export interface DriveDutyPoint {
	RequiredPower: number;
	TargetSpeed?: number;
	RelativeSpeed?: number;
}

export interface DriveParams {
	DutyPoints: DriveDutyPoint[];
	DriveArrangement?: DriveArrangement;
	PumpType: PumpType;
	PumpFrameId: string;
	PowerFactor?: number;
	MainsFreq?: number;
	Voltage?: number;
}

export interface DriveConfig {
	DriveArrangement: string;
	CrsMin: number;
	CrsMax: number;
	MaxSheaveMotor: number;
	MaxSheavePump: number;
	MaxSheaveWidth: number;
}

export interface MotorDutyPoint {
	CalculatedSpeed: number;
	RunningFreq: number;
	ShaftPower: number;
	PowerMargin: number;
}

export interface MotorResult extends MotorDutyPoint {
	// Static data
	id: string;
	MotorStandard: string; // IEC/NEMA
	Poles: number; // 2-8
	RatedPower: number; // Watts (kW in PD6 database)
	ShaftDiameter: number; // Millimetres
	ShaftLength: number; // Millimetres
	FullLoadSpeed: number; // RPM

	// Calculated
	DisplayName: string;
	MotorFrame: string;
	CustomVoltage?: boolean;
	DutyPoints: MotorDutyPoint[];
	Messages: ValidationResult[];

	// Selected based on PumpDriveOptions.DriveArrangement
	Drive: DriveConfig;
	MountingKit: string;
	NonStdMount?: boolean;
}


export enum DriveArrangement {
	None = 'None',

	// Horizontal options
	VBeltLeft = 'VBeltLeft',
	VBeltRight = 'VBeltRight',
	VBeltOverhead = 'VBeltOverhead',
	VBeltReverseOverhead = 'VBeltReverseOverhead',

	// Non-supported horizontal options
	// DirectNonSpacer = 'DirectNonSpacer',
	// DirectSpacer = 'DirectSpacer',
	// VBeltLayShaft = 'VBeltLayShaft',
	// ManualBelt = 'ManualBelt',

	// Vertical options
	VBeltDriveStandard = 'VBeltDriveStandard',
	VBeltDriveHighMount = 'VBeltDriveHighMount',

	// Non-supported vertical options
	// DirectCoupled = 'DirectCoupled',
	// GearReducer = 'GearReducer',
	CustomDrive = 'CustomDrive'
}

export interface PumpDriveOptions {
	UsableMotors: MotorResult[];
	UsableDriveArrangements: DriveArrangement[];
	Messages: ValidationResult[];
}

export interface Drive {
	DriveArrangement: DriveArrangement;
	DriveNotes: string;
	MotorNotes: string;
	UseVFD: boolean;
	TransmissionLoad: number;
	DriveRatio: number;
}

export enum BearingArrangement {
	Standard = 0,
	DoubleWet = 1,
	DoubleDrive = 2
}

export interface BuildInputs {
	TransmissionLoad: number;
	// Basic options (filter)
	FrameId: string;

	// Drive config inputs affecting (refining) calculated results
	DriveArrangement: DriveArrangement;
	BeltLoad: number;
	SheaveForce: number;
	DriveRatio: number;

	// Sizing data
	FlowRate: number;
	PDH: number;
	SlurryDensity: number;
	InletHead: number;
	InletHeadPosMargin?: number; // Extra head from InletHeadDesign up to InletHeadMax if specified
	InletHeadNegMargin?: number; // Extra head from InletHeadMin up to InletHeadDesign if specified

	// Calculated pump data
	BepFlow: number;
	BepHead: number;
	DutySpeed: number;
	ShutoffHead: number; // Needed for shaft seal

	// Static pump data for build (just passed to another service)
	Pump: any;
}

export interface BearingAssemblyResult {
	// Assembly metadata
	Id: string;
	FrameId: string;
	MaterialNumber: string;
	DrawingNumber: string;
	DisplayName: string;

	// Calculated values
	AxialLoad: number | null;
	RadialLoad: number | null;
	BearingWetLife: number | null;
	BearingDriveLife: number | null;
	BearingThrustLife: number | null;
	CritSpeedMin: number | null;
	CritSpeedMax: number | null;
	BearingArrangement: BearingArrangement;
	Messages: ValidationResult[];
}
