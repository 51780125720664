import { ParameterDef, ValidationResult } from 'types/dto/CalcServiceDomain';
import UnitValue from '@/common/UnitValue';
import { IParameterStorage } from './ParameterStorage';
import { MessageSeverity } from 'types/dto/CalcServiceDomain';
import { ParamBag } from '@/common/ParamBag';
import store, { SizingActions, SizingGetters } from '@/store';

export class ParamValue {
	private readonly paramDef: ParameterDef;
	private readonly storage: IParameterStorage;
	public readonly variants: IParameterStorage[];

	public constructor(pd: ParameterDef, storage: IParameterStorage, variants?: IParameterStorage[]) {
		this.paramDef = pd;
		this.storage = storage;
		this.variants = variants || [storage];
	}

	public getValue(): string {
		return this.storage.value as any as string;
	}

	public getUnitValue(imperial?: boolean, idx?: number): UnitValue {
		const unitId: string =
			(imperial && this.paramDef.PrefImperialUnit) ||
			this.paramDef.PreferredUnit ||
			this.paramDef.Unit;

		// Aggregates might have numeric unit but must remain strings (i.e. 123-456 rpm)
		const forceString = this.paramDef.Type === 'Aggregate';
		const value = idx == null ? this.storage.value : this.variants[idx].value;
		return new UnitValue(value, unitId, this.definition.Decimals, imperial, forceString);
	}

	public get errors(): ValidationResult[] {
		return this.messages.filter(x => x.Severity >= MessageSeverity.Error);
	}

	public get warnings(): ValidationResult[] {
		return this.messages.filter(x => x.Severity === MessageSeverity.Warning);
	}

	public get infos(): ValidationResult[] {
		return this.messages.filter(x => x.Severity === MessageSeverity.Info);
	}

	public get messages(): ValidationResult[] {
		return this.storage.messages() || [];
	}

	public get allMessages(): ValidationResult[] {
		return ParamBag.mergeMessages(...this.variants?.map(x => x.messages()), this.storage?.messages());
	}

	public get usedValue(): string {
		const usedVal = this.messages.find(x => x.Severity === MessageSeverity.UsedValue);
		return usedVal?.Message || null;
	}

	public get useImperialUnits(): boolean {
		return ParamBag.useImperial(this.storage?.metadata?.id);
	}

	public setValue(value: any, skipSave?: boolean): void {
		this.storage.setValue(value, skipSave);
	}

	public get canBeAssumed() {
		if (this.paramDef?.ReadOnly)
			return false;
		const id = this.storage?.metadata?.id;
		if (!id)
			return false;
		// If there is no project id, this is not a sizing and values can't be assumed to be assumable
		if (!this.storage.metadata.ProjectId)
			return false;
		return true;
	}

	public get isAssumed() {
		const id = this.storage?.metadata?.id;
		return id && store.get(SizingGetters.isAssumed, { id, valueName: this.valueName }) || false;
	}

	public toggleAssumed() {
		const sizingId = this.storage?.metadata?.id;
		const value = !this.isAssumed;
		if (sizingId)
			store.dispatch(SizingActions.setAssumed, { sizingId, valueName: this.valueName, value });
	}

	public get definition() { return this.paramDef; }
	public get group(): string { return this.paramDef.Name.split('.')[0]; }
	public get isArray(): boolean { return this.paramDef.Type.endsWith('[]'); }
	public get valueName(): string { return this.storage.valueName; }
	public get xlateSection(): string {
		if (this.definition.Type === 'Number' || this.definition.Type === 'Aggregate')
			return null;
		return this.definition.Unit || null;
	}
}
