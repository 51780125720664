<template>
	<v-list-item class="pl-6 pr-6">
		<v-list-item-content v-if="loading">
			Loading part data...
			<v-progress-circular :size="16" indeterminate />
		</v-list-item-content>

		<v-list-item-content v-else class="grid align-top">
			<SimpleWarnings v-if="messages && messages.length" :msgs="messages" class="mt-0 mb-0 py-0" />

			<div v-if="!locked && (!module || !module.name)" class="mb-8 pa-0">
				<div class="topinfo">
					<p v-if="inDiameter">Req. incoming inner ⌀: {{ inDiameter * 1000 }} mm</p>
					<p v-if="outDiameter">Req. outgoing inner ⌀: {{ outDiameter * 1000 }} mm</p>
					<p>Matching parts: {{ availableModules && availableModules.length }}</p>
				</div>
			</div>

			<p v-if="error && error.length" class="red--text mb-4" >{{ error }}</p>

			<div>
				<img v-if="partTypePic" :src="partTypePic" style="width: 40px; float: left" class="mt-0 ma-1 mr-2" />
				<v-select v-if="availablePartTypes.length" v-model="partType" :items="availablePartTypes" return-object
					label="Type of part" placeholder="Select type to proceed..." :disabled="locked || availablePartTypes.length < 2"
					outlined dense hide-details class="mb-4" />
				<div v-else-if="!loading && allModules != null" class="red--text mb-4">No compatible parts found</div>
			</div>

			<template v-if="partType">
				<v-select v-if="availableDiameters.length" v-model="selectedInNomSize" :items="availableDiameters"
					:label="hasOtherOutDiameter ? 'Incoming nominal size' : 'Nominal size'" :disabled="locked || availableDiameters.length < 2"
					outlined dense hide-details class="mb-4" />

				<v-select v-if="hasOtherOutDiameter" v-model="selectedOutNomSize" :items="availableOutDiameters"
					label="Outgoing nominal size" :disabled="locked || availableOutDiameters.length < 2"
					outlined dense hide-details class="mb-4" />

				<v-select v-if="availableLengths.length" v-model="selectedLength" :items="availableLengths" return-object
					label="Length (m)" :disabled="locked || availableLengths.length < 2"
					outlined dense hide-details class="mb-4" />

				<v-text-field v-if="module && module.name && selectedLength === CUT || cutLength != null" v-model="cutLength"
					label="Custom length (m)" :disabled="locked"
					outlined dense hide-details class="mb-4" />

				<v-select v-if="partType && availableModules.length" v-model="module" :items="availableModules" return-object
					label="Specific part" item-key="name" :item-text="moduleLabel" :readonly="locked || availableModules.length < 2"
					:placeholder="`${availableModules.length} matching parts`"
					outlined dense hide-details class="wide mb-4">
					<template #selection="{ item }">
						<div style="display: inline-block; overflow: hide; text-wrap: nowrap; max-width: 90%;">
							<DeliveryReadinessIcon :dr="item.dr" class="mr-1" />
							{{ moduleLabel(item) }}
						</div>
					</template>
					<template #item="{ props, item, on }">
						<v-list-item v-bind="props" v-on="on">
							<DeliveryReadinessIcon :dr="item.dr" class="mr-2" />
							{{ moduleLabel(item) }}
						</v-list-item>
					</template>
				</v-select>

				<div class="wide">
					<UnitNumeric :param="getParam('DepositionVelocity')" inline />
					<UnitNumeric :param="getParam('BottomVelocity')" inline />
					<UnitNumeric :param="getParam('Velocity')" inline />
					<UnitNumeric :param="getParam('ExtraLength')" inline />
					<UnitNumeric :param="getParam('TaperPieceEqLength')" inline />
					<UnitNumeric :param="getParam('FrictionLoss')" inline />
				</div>
			</template>

			<div v-if="debugging" class="wide">
				<span @click="showDebugInfo = !showDebugInfo" title="Show debug data" style="cursor: pointer">👁</span>
				<div v-if="showDebugInfo">
					<div v-if="filter" class="mb-4 mt-4">
						<p><b>Constraints</b></p>
						<p v-for="[k, v] of Object.entries(filter).filter(x => x[1])" :key="k" style="font-weight: normal; margin-bottom: 3px">
							{{k}} = <b>{{ v }}</b>
						</p>
					</div>
					<div v-if="debugMessages && debugMessages.length" class="mb-4">
						<p><b>All messages</b></p>
						<SimpleWarnings :msgs="debugMessages" class="my-0 py-0" />
					</div>
					<div class="mb-4">
						<p><b>Contents</b></p>
						<pre class="mb-4">{{ debugDump }}</pre>
					</div>
				</div>
			</div>
		</v-list-item-content>
	</v-list-item>
</template>

<style type="text/css">
.topinfo {
	background-color: rgb(0,0,255,0.1);
	margin-top: -4px; /* v-select is weird, so try to align to it*/
	padding: 3px;
}
.topinfo p {
	margin: 3px;
	font-weight: normal;
}
</style>

<script lang="ts">
	import Vue from 'vue';
	import { Component, Prop, Watch } from 'vue-property-decorator';
	import { ParamBag } from '@/common/ParamBag';
	import UnitNumeric from '@/components/Fields/UnitNumeric.vue';
	import SelectField from '@/components/Fields/SelectField.vue';
	import SimpleWarnings from '@/components/SimpleWarnings.vue';
	import { uniqueValues } from '@/common/Tools';
	import { MessageSeverity, Pipe, ValidationResult } from 'types/dto/CalcServiceDomain';
	import { PipingModule } from 'types/dto/PipingModule';
	import UnitValue from '@/common/UnitValue';
	import { PipingFilter, equivalentBendLength, getMatchingPipes, isCuttable, summarizeErrors } from '@/common/PipingManager';
	import DeliveryReadinessIcon from '@/components/DeliveryReadinessIcon.vue';
	import store, { DebugGetters } from '@/store';

	const appTitle = process.env.VUE_APP_TITLE;
	type LengthType = number | 'Custom';

	@Component({
		components: {
			UnitNumeric,
			SelectField,
			SimpleWarnings,
			DeliveryReadinessIcon
		}
	})
	export default class PipePartEditor extends Vue {
		@Prop() public index: number;
		@Prop() public target: string;
		@Prop() public values: ParamBag;
		@Prop() public locked: boolean;
		@Prop() public prevPipe: Pipe;
		@Prop() public nextPipe: Pipe;
		@Prop() public reverseFlow: boolean;

		public readonly CUT: LengthType = 'Custom';
		public allModules: PipingModule[] = null;
		public partType: string = null;
		public selectedLength: LengthType = null;
		public selectedInNomSize: string = null;
		public selectedOutNomSize: string = null;
		public cutLength?: number = null;
		public module: PipingModule = null;
		public error: string = null;
		public loading = false;
		public showDebugInfo = false;
		public removals: any = {};
		public prevFilter: string = null;

		public async created() {
			this.loadModule();
			this.$watch('saveTrigger', () => this.saveModule());
			this.$watch('filter', () => this.searchModules(), { immediate: true });
		}

		public readonly debugging = appTitle.includes('Local') || appTitle.includes('Development');

		public moduleLabel = (x: PipingModule) => x?.name?.replaceAll('_', ' ') ?? '';

		public get inDiameter() { return this.prevPipe?.OutDiameter ?? this.prevPipe?.Diameter; }

		public get outDiameter() { return this.nextPipe?.Diameter; }

		public get calcMessages() { return this.getParam('Diameter').messages.filter(x => x.Severity >= MessageSeverity.Info); }

		public getPipeParamName(name?: keyof Pipe) {
			const fieldRef = name ? ('.' + name) : '';
			return `${this.target}[${this.index}]${fieldRef}`;
		}

		public getParam(name: keyof Pipe) {
			return this.values?.getParam(this.getPipeParamName(name), `Pipe.${name}`);
		}

		public setValue(name: keyof Pipe, value: any) {
			this.getParam(name).setValue(value, true);
		}

		public get messages() {
			const msgs = this.calcMessages ?? [];
			const partMsgs = this.module?.messages ?? [];
			return [...msgs, ...partMsgs];
		}

		public get debugMessages() {
			if (!this.debugging && !this.showDebugInfo)
				return;

			const all = store.get(DebugGetters.log) as ValidationResult[];
			const myName = this.getPipeParamName();
			const entries = all.filter(x => x.ParamName?.startsWith(myName))
				.map(x => ({ ...x, Severity: Math.max(x.Severity, MessageSeverity.Info) }));
			return [...this.messages, ...entries];
		}

		public get partTypePic() {
			let name: string = null;
			switch (this.partType) {
				case 'BEND': name = 'bend'; break;
				case 'PIPE': name = 'pipe'; break;
				case 'BRANCH PIPE': name = 'branch_pipe'; break;
				case 'HOSE': name = 'hose'; break;
				case 'REDUCER/EXPANDER': name = 'reducer_expander'; break;
				case 'COMPENSATOR': name = 'compensator'; break;
			}
			return name ? require(`@/assets/piping_${name}.svg`) : null;
		}

		get useImperial() {
			return ParamBag.useImperial(this.values?.sizingId);
		}

		public getValue(name: keyof Pipe) {
			const val = this.getParam(name).getValue() as any;
			return val != null && !isNaN(val) ? Number(val) : null;
		}

		public get hasOtherOutDiameter() {
			if (!this.selectedInNomSize)
				return false;
			return this.selectedOutNomSize && this.selectedOutNomSize != this.selectedInNomSize || this.availableOutDiameters.length > 1;
		}

		get availableDiameters() {
			return uniqueValues(this.availableModules.map(x => x.properties.nominalSizeIn));
		}

		get availableOutDiameters() {
			return uniqueValues(this.availableModules.map(x => x.properties.nominalSizeOut));
		}

		get availablePartTypes() {
			const subareas = this.availableModules.map(x => x.subArea);
			const all = uniqueValues(subareas);
			all.sort();
			return all;
		}

		get availableLengths() {
			if (!['HOSE', 'PIPE'].includes(this.partType))
				return [];
			const allLengths = this.availableModules.filter(x => !isCuttable(x)).map(x => x.properties.componentLength);
			const lengths = uniqueValues(allLengths);
			if (this.availableModules.some(isCuttable))
				lengths.push(this.CUT as string);
			return lengths as LengthType[];
		}

		get d80() { return (this.values.getNumericValue('Particles.d80') ?? 0) * 1000.0; }

		get liquidTemp() { return this.values.getNumericValue('Liquid.Temp'); }

		get drillingPatterns() {
			let prevPipeDrilling = this.prevPipe?.Module?.properties?.inletFlangePressureClass;
			if (typeof prevPipeDrilling === 'string')
				return [prevPipeDrilling];
			if (prevPipeDrilling?.length)
				return prevPipeDrilling;

			const pumpDrilling = (this.values.getParam('Flange')?.getValue() as any)?.id as string;
			if (pumpDrilling)
				return [pumpDrilling];
		}

		get pressureHead() {
			let head: number;
			if (this.target.includes('Inlet'))
				head = this.values.getNumericValue('Heads.InletHead') ?? 0;
			else {
				const pdh = this.values.getNumericValue('Heads.PDH') ?? 0;
				const inletHead = this.values.getNumericValue('Heads.InletHead') ?? 0;
				head = pdh + inletHead;
			}
			return head > 0 ? head : null;
		}

		barsFromHead(head: number) {
			const density = this.values.getNumericValue('Slurry.SlurryDensity') ?? 0;
			if (head == null || isNaN(head) || density == null || isNaN(density))
				return null;
			const pressure = head * density * 9.81 / 1e5;
			return Math.round(100 * pressure) / 100.0;
		}

		get filter(): PipingFilter {
			const wantsCut = this.selectedLength === this.CUT;

			return {
				reverseFlow: this.reverseFlow ?? undefined,
				partType: this.partType ?? undefined,
				liquidTemp: this.liquidTemp ?? undefined,
				pressure: this.barsFromHead(this.pressureHead) ?? undefined,
				particleD80: this.d80 ?? undefined,
				inNominalSize: this.selectedInNomSize ?? undefined,
				outNominalSize: this.selectedOutNomSize ?? undefined,
				inDiameter: this.inDiameter,
				componentLength: wantsCut ? undefined : UnitValue.asNumber(this.selectedLength) ?? undefined,
				cuttable: wantsCut ? true : this.selectedLength ? false : undefined,
				cutLength: UnitValue.asNumber(this.cutLength) ?? undefined,
				drillingPatterns: this.drillingPatterns ?? undefined,
				moduleName: this.module?.name ?? undefined
			};
		}

		get availableModules() {
			// Single module already selected 
			if (this.module?.name)
			 	return [this.module];
			if (this.loading || !this.allModules?.length)
				return [];
			return this.allModules;
		}
		
		public async searchModules() {
			if (this.loading)
				return;

			const newFilter = JSON.stringify(this.filter);
			if (this.prevFilter === newFilter)
				return;
			this.prevFilter = newFilter;

			// Initial load of module data and no module selected; show spinner while loading
			if (this.allModules == null && !this.module?.name)
				this.loading = true;

			try {
				const data = await getMatchingPipes(this.filter);
				data?.forEach(m => m.messages ??= []);
				this.error = null;

				if (this.filter.moduleName && data?.length === 1) {
					// Merge new module version with currently selected module and update messages (duty changes etc)
					if (this.module?.customLength != null)
						data[0].customLength = this.module.customLength;
					if (this.module?.quantity != null)
						data[0].quantity = this.module.quantity;
					
					// TODO: detect base data changes (all but quantity, customLength and messages) and ask user what to do
					this.setValue('Module', data[0]);
					this.module = data[0];
				}

				this.allModules = data;
			} catch (error) {
				this.error = 'Part search failed';
			}
			this.loading = false;
		}

		private loadModule() {
			const module = this.getParam('Module').getValue() as any as PipingModule;
			this.module = module;
			if (module?.subArea)
				this.partType = module.subArea;

			if ([true, 'true'].includes(module?.properties?.canBeCut)) {
				this.cutLength = module.customLength;
				this.selectedLength = this.CUT;
			} else if (['HOSE', 'PIPE'].includes(module?.subArea)) {
				// For hoses and pipes, filter on selected length. Not relevant otherwise.
				const len = module?.properties?.componentLength;
				if (len > 0)
					this.selectedLength = len;
			}
			
			if (module?.properties) {
				this.selectedInNomSize = module.properties.nominalSizeIn ?? null;
				this.selectedOutNomSize = module.properties.nominalSizeOut ?? null;
			}

			// Kick calculation to put calc debug messages in debug log
			equivalentBendLength(module, this.getPipeParamName());
		}

		@Watch('availablePartTypes')
		public availablePartTypesChanged() {
			if (this.availablePartTypes?.length === 1)
				this.partType = this.availablePartTypes[0];
		}

		@Watch('availableDiameters')
		public availableDiametersChanged() {
			if (this.availableDiameters?.length === 1)
				this.selectedInNomSize = this.availableDiameters[0];
		}

		@Watch('availableOutDiameters')
		public availableOutDiametersChanged() {
			if (this.availableOutDiameters?.length === 1)
				this.selectedOutNomSize = this.availableOutDiameters[0];
		}

		@Watch('availableLengths')
		public availableLengthsChange() {
			if (this.availableLengths?.length === 1)
				this.selectedLength = this.availableLengths[0];
		}

		@Watch('availableModules')
		public availableModulesChange() {
			if (this.availableModules?.length === 1)
				this.module = this.availableModules[0];
		}

		@Watch('selectedLength')
		public selectedLengthChanged() {
			if (this.selectedLength !== this.CUT)
				this.cutLength = null;
		}

		get editedModule(): PipingModule {
			const newModule = JSON.parse(JSON.stringify(this.module ?? {}));
			newModule.quantity ??= 1;
			newModule.messages ??= [];
			newModule.customLength = UnitValue.asNumber(this.cutLength);
			return newModule;
		}

		get saveTrigger() {
			return JSON.stringify(this.editedModule ?? {});
		}

		public saveModule() {
			if (this.locked)
				return;

			const m = this.editedModule;
			// Extract and store SPDB values for backend friction calc etc
			const rawInDia = m?.properties?.innerDiameterIn;
			this.setValue('Diameter', rawInDia == null || isNaN(rawInDia) ? null : rawInDia / 1000.0);

			const rawOutDia = m?.properties?.innerDiameterOut || m?.properties?.innerDiameterIn;
			this.setValue('OutDiameter', rawOutDia == null || isNaN(rawOutDia) ? null : rawOutDia / 1000.0);

			const rough = m?.properties?.roughness;
			this.setValue('RoughnessHeight', rough > 0 ? rough / 1000.0 : null);

			// Set actual length
			const moduleLength = this.selectedLength ?? m?.properties?.componentLength;
			let actualLength: number;
			if (moduleLength === this.CUT)
				actualLength = m.customLength > 0 ? m.customLength : null;
			else
				actualLength = UnitValue.asNumber(moduleLength);

			this.setValue('Length', actualLength);
			this.setValue('ExtraLength', equivalentBendLength(m, this.getPipeParamName()) ?? null);
			this.setValue('Module', m);
			this.$emit('changed', this.index);
		}

		public get debugDump() {
			const props: (keyof Pipe)[] = ['Diameter', 'Length', 'OutDiameter', 'RoughnessHeight', 'Module'];
			const data: any = {};
			props.forEach(p => data[p] = this.getValue(p) ?? undefined);
			data['Module'] ??= this.editedModule ?? undefined;
			data['PressureHead'] = Math.round(10 * this.pressureHead) / 10.0;
			if (this.removals)
				data.Removals = this.removals;
			if (data.Module?.messages)
				delete data.Module.messages;
			return JSON.stringify(data, null, 3).replace(/\\"/g, '”').replace(/"([^"]+)"/g, '$1').replace(/,\n/g, '\n');
		}

		@Watch('showDebugInfo')
		public async fetchRemovals(show: boolean) {
			if (!show)
				this.removals = null;
			else {
				const data = await getMatchingPipes({ ...this.filter, includeInvalid: true });
				this.removals = summarizeErrors(data);
			}
		}
	}
</script>