<template>
	<article>
		<v-container>
				<!-- Title -->
				<v-row>
					<v-col>
						<h1 class="py-2">{{ $t('app.perfCurveViewer') }}</h1>
					</v-col>
				</v-row>
				<!-- Pump selector -->
				<v-row>
					<v-col>
						<v-card outlined class="fill-height base-padding">
								<v-card-title>
									<h3>Select pump</h3>
								</v-card-title>
								<v-card-text>
									<v-row>
										<v-col cols="12" sm="4">
											<v-select outlined v-if="pumps" :items="ranges" v-model="series" label="Series" hide-details no-data-text="Loading...">
												<template #item="{ item }">
													{{ item }}
													<span class="ml-1" :title="statusText(seriesStatus[item], 'series')">{{ statusIcon(seriesStatus[item]) }}</span>
												</template>
												<template #selection="{ item }">
													{{ item }}
													<span class="ml-1" :title="statusText(seriesStatus[item], 'series')">{{ statusIcon(seriesStatus[item]) }}</span>
												</template>
											</v-select>
										</v-col>
										<v-col cols="12" sm="4">
											<v-select class="col-3" outlined v-if="pumps" :items="modelsInSeries" label="Model" item-value="Model"
												hide-details item-text="Model" v-model="model" :disabled="!series" no-data-text="Loading...">
												<template #item="{ item }">
													{{ item }}
													<span class="ml-1" :title="statusText(modelStatus[item], 'model')">{{ statusIcon(modelStatus[item]) }}</span>
												</template>
												<template #selection="{ item }">
													{{ item }}
													<span class="ml-1" :title="statusText(modelStatus[item], 'model')">{{ statusIcon(modelStatus[item]) }}</span>
												</template>
											</v-select>
										</v-col>
										<v-col cols="12" sm="4">
											<v-select outlined v-if="pumps" :items="pumpsInModel" v-model="pumpId" label="Configuration" item-text="DisplayName"
												item-value="Id" hide-details :disabled="!model || !series" @change="gotoPump" no-data-text="Loading...">
												<template #item="{ item }">
													{{ item.DisplayName }}
													<span class="ml-1" :title="statusText(item.Status, 'configuration')">{{ statusIcon(item.Status) }}</span>
												</template>
												<template #selection="{ item }">
													{{ item.DisplayName }}
													<span class="ml-1" :title="statusText(item.Status, 'configuration')">{{ statusIcon(item.Status) }}</span>
												</template>
											</v-select>
										</v-col>
									</v-row>
								</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<!-- Report settings -->
				<v-row>
					<v-col :cols="12 - curveCols">
						<v-card outlined class="fill-height base-padding">
								<v-card-title>
									<h3>Output settings</h3>
								</v-card-title>
								<v-card-text>
									<div class="grid mt-2">
										<!-- System of units -->
										<v-select outlined class="chevron mb-4" :items="sous" v-model="reportSystemOfUnit"
											:label="$t('Site.SystemOfUnits')" persistent-hint hide-details>
										</v-select>
										<!-- Language -->
										<v-select outlined class="chevron mb-4" v-if="languages && languages.length > 1"
											:items="languages" v-model="language" :label="$t('app.language')" persistent-hint hide-details>
										</v-select>
										<v-text-field v-if="trimmable" outlined v-model="trimText" type="number"
											:error-messages="trimError" :label="$t('Pump.TrimPercentage')" persistent-hint>
										</v-text-field>
									</div>

									<div class="grid buttons mt-5">
										<v-btn dark @click="generateSummary(false)" :disabled="working || !pump">Create PDF</v-btn>
										<v-btn @click="generateSummary(true)" :disabled="working || !pump">Show preview</v-btn>
										<v-progress-circular v-if="working" :size="20" indeterminate class="mt-2" />
									</div>
								</v-card-text>
						</v-card>
					</v-col>

					<v-col :cols="curveCols">
						<v-card outlined class="fill-height base-padding">
							<v-card-title>
								<h3>Curve settings
									<template v-if="pump && pump.DisplayName">for {{ pump.DisplayName }}</template>
								</h3>
							</v-card-title>
							<v-card-text>
								<PumpCurve v-if="pumpId" :pumpId="pumpId" :noZoom="true" @settings="curveSettingsChanged"
									:showNPSHR="pump && pump.Type === 'Horizontal'" :defaults="{ IncludeNPSH: true }"
									:forceImperial="reportSystemOfUnit === 'Imperial'" :forceTrim="this.trimError ? null : this.trimPercentage" />
								<div v-else>
									Select pump...
								</div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
		</v-container>
		<!-- Preview dialog -->
		<v-dialog v-model="showPreview" width="1150" height="90%" @keydown.esc="showPreview = false">
				<div class="preview-container">
					<div class="inner-container">
						<v-btn small @click="showPreview = false" class="close-preview"><b>X</b></v-btn>
					</div>
					<iframe v-show="showPreview" width="1120" :height="frameHeight" id="previewHtml" frameborder="0"></iframe>
				</div>
		</v-dialog>
	</article>
</template>

<script lang="ts">
import { PerformancePreview } from '@/common/PerformancePreview';
import SizingInfo from '@/common/SizingInfo';
import { uniqueValues } from '@/common/Tools';
import PumpCurve from '@/components/PumpCurve.vue';
import i18n, { fallbackLanguage, languages } from '@/i18n';
import InsightService from '@/services/insight.service';
import pumpsService, { PumpEntry } from '@/services/pumps.service';
import ReportingService from '@/services/reporting.service';
import store, { AuthGetters, SnackActions } from '@/store';
import { UserSetting } from '@/store/modules/auth.store';
import { PumpStatus } from 'types/dto/PumpSearch';
import { ReportInputs } from 'types/dto/Reports';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

let lastSettings: any = null;

@Component({
	components: { PumpCurve },
})
export default class Performance extends Vue {
	@Prop() id: string;

	public series: string = "";
	public model: string = "";
	public pumps: PumpEntry[] = [];
	public pumpId: string = '';
	public trimText: string = null;

	public working = false;
	public showCurves = true;
	public showPreview = false;
	public curveOnly = true;
	public curveCols = 8;
	public frameHeight = 2850;

	public curveSettings: Partial<ReportInputs> = null;
	public language: string = lastSettings?.Language || i18n.locale || fallbackLanguage;
	public selectedSystemOfUnit: string = lastSettings?.UseImperial ? 'Imperial' : null;

	public get settings(): Partial<ReportInputs> {
		return {
				UseImperial: this.reportSystemOfUnit === 'Imperial',
				ShowCurves: true,
				CurveOnly: true,
				Language: this.language || undefined
		};
	}

	public set settings(s: Partial<ReportInputs>) {
		if (s) {
				this.reportSystemOfUnit = s.UseImperial ? 'Imperial' : 'Metric';
				this.showCurves = s.ShowCurves;
				this.curveOnly = s.CurveOnly;
				this.language = s.Language || fallbackLanguage;
		}
	}

	public get languages() {
		return languages();
	}

	public get ranges() {
		return uniqueValues(this.pumps, 'Series').sort();
	}

	public get sous() {
		return [
				{ text: this.$t('SystemOfUnits.Metric'), value: 'Metric' },
				{ text: this.$t('SystemOfUnits.Imperial'), value: 'Imperial' }
		];
	}

	public get reportSystemOfUnit() {
		return this.selectedSystemOfUnit || store.get(AuthGetters.systemOfUnit) || 'Metric';
	}

	public set reportSystemOfUnit(sou) {
		if (sou)
				this.selectedSystemOfUnit = sou;
	}

	public get trimmable() {
		return this.pump?.MinTrimPercentage > 0 && this.pump?.MinTrimPercentage < 100;
	}

	public get trimPercentage() {
		const userTrim = this.trimText?.length ? parseFloat(this.trimText) : null;
		return userTrim > 0 && userTrim < 100 ? userTrim : null;
	}

	public get trimError() {
		const userTrim = this.trimPercentage;
		if (userTrim == null)
			return;
		const minTrim = this.pump?.MinTrimPercentage;
		if (userTrim > 0 && minTrim == null)
			return 'Pump can not be trimmed';
		if (minTrim > 0 && userTrim < minTrim)
			return `Allowed trim range is ${minTrim}-100 %`;
	}

	private getSingletonStatuses(prop: keyof PumpEntry) {
		const statuses: { [key: string]: PumpStatus | 'Many' } = {};
		for (const pump of (this.pumps ?? [])) {
			const existing = statuses[pump[prop]];
			if (existing === 'Many')
				continue;
			else if (existing === undefined)
				statuses[pump[prop]] = pump.Status ?? null;
			else if (existing !== pump.Status)
				statuses[pump[prop]] = 'Many';
		}
		return statuses;
	}

	public get seriesStatus() {
		return this.getSingletonStatuses('Series');
	}

	public get modelStatus() {
		return this.getSingletonStatuses('Model');
	}

	public pumpsInRange(range: string) {
		return this.pumps.filter(x => x.Series === range);
	}

	public get modelsInSeries() {
		if (!this.series || !this.pumps?.length)
			return [];
		const pumpsInSeries = this.pumps.filter(x => x.Series === this.series);
		return uniqueValues(pumpsInSeries, 'Model').sort();
	}

	public get pumpsInModel() {
		if (!this.series || !this.model || !this.pumps?.length)
			return [];
		return this.pumps.filter(x => x.Model === this.model && x.Series === this.series);
	}

	public get pump() {
		if (!this.series || !this.model || !this.pumpId)
			return undefined;
		if (this.pumps?.length)
			return this.pumps.find(x => x.Id === this.pumpId);
	}

	public curveSettingsChanged(settings: Partial<ReportInputs>) {
		this.curveSettings = settings;
	}

	public statusIcon(s: PumpStatus | 'Many') {
		return s === 'Many' ? '' : SizingInfo.pumpStatusIcon({ Status: s });
	}

	public statusText(s: PumpStatus | 'Many', ofWhat: string) {
		return s === 'Many' ? '' : s + ' ' + ofWhat;
	}

	public gotoPump(id: string) {
		this.pumpId = id;
		history.pushState({}, undefined, '#/performance/' + (id ?? ''));
	}

	public generateSummary(preview: boolean) {
		store.dispatch(SnackActions.set, 'Generating report');
		const filename = `Performance_Curve_${this.pump.Id}_${this.reportSystemOfUnit}`;
		const ri: Partial<ReportInputs> = {
			ModelId: this.pumpId,
			Filename: filename,
			HtmlView: preview || undefined,
			RootUrl: window.location.origin + window.location.pathname,
			TrimPercentage: this.trimError == null && this.trimPercentage || undefined
		};

		Object.assign(ri, this.curveSettings || {}, this.settings);

		this.working = true;

		ReportingService.generateSummary(ri as ReportInputs)
			.then(data => {
				const evt = (preview ? 'Performance:Preview' : 'Performance:CreatePDF');
				InsightService.trackEvent(evt, ri);
				if (preview) {
					this.showPreview = true;
					PerformancePreview(data, 'previewHtml');
				}
			})
			.catch(() => {
				InsightService.trackEvent('Performance:Error', ri);
				store.dispatch(SnackActions.set, 'Unable to generate curve. Please contact support.');
			})
			.finally(() => this.working = false);
		return true;
	}

	public async created() {
		const defaults = store.get(AuthGetters.setting, UserSetting.reportSettings);
		if (defaults)
				lastSettings = this.settings = defaults;
		try {
				this.pumps = await pumpsService.getPumpEntries();
				if (this.id) {
					const pump = this.pumps.find(x => x.Id === this.id);
					if (pump) {
						this.pumpId = pump.Id;
						this.model = pump.Model;
						this.series = pump.Series;
					}
				}
		} catch (e) {
				console.error('Failed to get pump list');
		}
	}

	@Watch('modelsInSeries')
	public modelsInSeriesChanged() {
		if (this.series && this.modelsInSeries.length === 1)
			this.model = this.modelsInSeries[0];
	}

	@Watch('pumpsInModel')
	public onPumpsInModelChanged() {
		if (this.model && this.pumpsInModel.length === 1)
			this.gotoPump(this.pumpsInModel[0].Id);
	}

	@Watch('series')
	public seriesChanged(newVal: string) {
		if (!newVal || this.pump?.Series !== newVal)
			this.pumpId = this.model = null;
	}

	@Watch('model')
	public modelChanged(newVal: string) {
		if (!newVal || this.pump?.Model !== newVal)
			this.pumpId = null;
	}	

	@Watch('pumpId')
	public pumpIdChanged() {
		this.trimText = null;
	}	
}
</script>

<style lang="scss" scoped>
.grid {
	display: grid;
	row-gap: 0;
	grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
	grid-gap: 0 16px;

	&.buttons {
		grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
		grid-gap: 10px 16px;
	}
}

.preview-container {
	background-color: white;
	padding-top: 60px;

	.inner-container {
		position: relative;
	}

	.close-preview {
		position: absolute;
		right: 20px;
		top: -40px;
	}
}

@media only screen and (max-width: 960px) {
	.content-wrapper {
		margin-top: 55px;
	}
}
</style>